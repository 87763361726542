
    <div style="padding-top: 100px" class="container-fluid">

        <div style="margin-bottom: 10px;" class="row">
            <div class="col-lg-12">
                <button mat-raised-button (click)="openDialog()" class="mb-4">Add new {{typeName}}</button>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                {{typeName}} List
            </div>
        
            <div class="card-body">
                <div class="table-responsive">
                    <table class=" table table-bordered table-striped table-hover datatable brand-table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th> Actions </th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="data">
                                <tr data-entry-id="" *ngFor='let item of data;'>
                                    <td>{{ item.id }}</td>
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.email }}</td>
                                    <td>{{ item.phone }}</td>
                                    <td>
                                        <a class="btn btn-xs btn-primary mr-2"  routerLink="/companies/type/{{ type }}/{{ item.slug }}">
                                            View
                                        </a>
                                        <a class="btn btn-xs btn-danger" (click)="openDelete(item.slug)">
                                            Delete
                                        </a>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>


    </div>
