import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public loadingPercent: BehaviorSubject<any> = new BehaviorSubject(0);
    public resetLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() { }
}
