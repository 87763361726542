<div class="add-dialog" >
    <div class="add-dialog-header">
        <button mat-dialog-close matDialogClose class="close">
            <span aria-hidden="true">×</span>
        </button>
        <ng-container>
            <h3 mat-dialog-title>Add persons in bulk</h3>
        </ng-container>
    </div>
    <div mat-dialog-content class="add-dialog-content">
        <form [formGroup]="modalFormGroup" (submit)="submit()" id="ngForm">
            <div class="body-content" cdkScrollable>
                <div class="inputs-wrapper">
                    <div class="modal-info">
                        <!-- Position -->
                        <mat-form-field class="example-chip-list" appearance="standard">
                            <mat-label class="label">Position</mat-label>
                            <mat-chip-list #chipList aria-label="Position select">
                                <mat-chip *ngFor="let position of positions" class="positionItem" (removed)="removePosition(position)">
                                    {{position}}
                                    <button matChipRemove>
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip>
                                <input placeholder="New Position..." #positionInput matInput [formControl]="position"
                                    [matAutocomplete]="auto" [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="addPosition($event)" class="input">
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedPosition($event)">
                                <mat-option *ngFor="let positions of filteredPositions | async" [value]="positions.id">
                                    {{positions.name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <!-- Skills -->
                        <mat-form-field class="example-chip-list" appearance="standard">
                            <mat-label class="label">Skills</mat-label>
                            <mat-chip-list #chipList2 aria-label="Skill select">
                                <mat-chip *ngFor="let skill of skills" class="skillItem" (removed)="removeSkill(skill)">
                                    {{skill}}
                                    <button matChipRemove>
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip>
                                <input placeholder="New Skill..." #skillInput matInput [formControl]="skill"
                                    [matAutocomplete]="auto2" [matChipInputFor]="chipList2"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="addSkill($event)" class="input">
                            </mat-chip-list>
                            <mat-autocomplete #auto2="matAutocomplete" (optionSelected)="selectedSkill($event)">
                                <mat-option *ngFor="let skills of filteredSkills | async" [value]="skills.id">
                                    {{skills.name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                          <!-- Name -->
                        <mat-form-field class="full-width-input" appearance="standard">
                            <mat-label class="label">Name</mat-label>
                            <input matInput type="text" autocomplete="new-password" class="input" formControlName="names"
                                #namesInput required [autofocus]="focusInput">
                            <mat-error class="error" *ngIf="(modalFormGroup.get('names')).hasError('required')">Name is
                                required</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div mat-dialog-actions align="end">
                <button mat-raised-button class="f-sub-header-3" color="primary" type="submit" [disabled]="!modalFormGroup.valid">Add Bulk</button>
            </div>
        </form>
    </div>
</div>