import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timer } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CacheResolverService {
    private valueResponse:HttpResponse<any>;

    constructor() { 
        // Delete cache after 1 hour
        setInterval(()=> { this.clearCache() }, 1800000);
    }

    set(key, value, timeToLive:number|null = null){
        let url = key.replace('https://b-network.staaging.com/api/','')
        
        if(timeToLive){
            const expiresIn = new Date();
            expiresIn.setSeconds(expiresIn.getSeconds() + timeToLive);
            this.valueResponse = value;
            localStorage.setItem(url, JSON.stringify({"value": this.valueResponse, "expiresIn": expiresIn}));
        } else {
            localStorage.setItem(url, JSON.stringify(value));
        }
    }

    get(key){
        let url = key.replace('https://b-network.staaging.com/api/','')
        const localStorageData = JSON.parse(localStorage.getItem(url))
        if(!localStorageData) return null;

        const expiresIn = localStorageData.expiresIn;
        const httpSavedResponse = new HttpResponse (localStorageData.value);
        const now = new Date();

        if(expiresIn && expiresIn < now.getTime()){
            localStorage.removeItem(url);
            return null;
        }
        return httpSavedResponse;
    }

    delete(key){
        let url = key.replace('https://b-network.staaging.com/api/','')
        localStorage.removeItem(url);
    }

    clearCache(){
        const cacheItems = { ...localStorage };
        const keysArray = Object.keys(cacheItems);
        for (let i = 0; i < keysArray.length; i++) {
            if(!keysArray[i].includes('token')){
                this.delete(keysArray[i])
            }
        }
    }
}
