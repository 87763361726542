import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '../config';
import { TokenService } from './auth/token.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
    config:Config;
    constructor(private http: HttpClient, private token: TokenService) { }

    headers = new HttpHeaders({'Content-Type': 'application/json', 'Authorization':`Bearer ${this.token.get()}`});

    checkEmailDns(data){
      return this.http.post(Config.url + 'checkEmailDns', data , {headers:this.headers});
    }

    isNumber(variable: any): boolean {
      return typeof variable === 'number' && !isNaN(variable);
    }
}
