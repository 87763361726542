import { Component } from '@angular/core';
import { TokenService } from './services/auth/token.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'network-front';
    loggedIn = false;

    constructor(private token: TokenService){
        this.token.isLoggedIn.subscribe((res)=> {
            if(res || this.token.loggedIn()){
                this.loggedIn = true;
            } else {
                this.loggedIn = false
            }
        })
    }
}
