import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CustomDialogService {
  public customModalData: Subject<any> = new Subject();
  public customModalOpened = new BehaviorSubject<boolean>(false);
  cast = this.customModalOpened.asObservable();
  public  customModalEditMode = new BehaviorSubject<boolean>(false);
  customEdit = this.customModalEditMode.asObservable();

  public  customModalisOpen = new BehaviorSubject<boolean>(false);

  constructor() { }

  toogleOpen(){
    this.customModalOpened.next(!this.customModalOpened);
  }

  open(){
    this.customModalOpened.next(true);
    this.customModalisOpen.next(true);
    // this.updateBodyClass();
  }
  
  close(){
    this.customModalOpened.next(false);
    this.customModalisOpen.next(false);
    // this.updateBodyClass();
  }

  // private updateBodyClass() {
  //   const body = document.body;
  //   const modalOpenClass = 'custom-modal-open';

  //   if (this.customModalOpened.value) {
  //     body.classList.add(modalOpenClass);
  //   } else {
  //     body.classList.remove(modalOpenClass);
  //   }
  // }
}
