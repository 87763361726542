import { Component, OnDestroy, OnInit } from '@angular/core';
import { Person } from 'src/app/interfaces/person';
import { PersonService } from 'src/app/services/person.service';
import { OpendialogService } from 'src/app/services/opendialog.service';
import { NotifierService } from 'angular-notifier';
import { PeopleText } from '../../lang/globals-en';
import { AddDialogComponent } from '../dialogs/add-dialog/add-dialog.component';
import { PositionService } from 'src/app/services/position.service';
import { SmallDialogComponent } from '../dialogs/small-dialog/small-dialog.component';
import { Title } from '@angular/platform-browser';
import { SeoService } from 'src/app/services/seo.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-people',
    templateUrl: './people.component.html',
    styleUrls: ['./people.component.scss'],
})
export class PeopleComponent implements OnInit, OnDestroy {
    people: Person[];
    peopleNotification = PeopleText;
    error: null;
    errorStatus: null;
    private subscribtion: Subscription;
    private subscribtionDelete: Subscription;
    private deletePersonSubscription: Subscription;
    private submitPersonSubscription: Subscription;
    allPositions: any;

    constructor(
        private personService: PersonService,
        private openDialogService: OpendialogService,
        private notifier: NotifierService,
        private positionService: PositionService,
        private seoService: SeoService
    ) {
        this.seoService.setSeoMetaTags({title: 'People'});

        this.subscribtion = this.openDialogService.modalData.subscribe((res:any) => {
            if (res.type == 'person') {
                this.submit(res.data);
            }
        })

        this.deletePersonSubscription = this.openDialogService.deletePerson.subscribe((res: any) => {
            this.delete(res.slug)
        })

    }

    ngOnInit(): void {
        this.getPeople();
    }

    ngOnDestroy(): void {
        if(this.subscribtion){
            this.subscribtion.unsubscribe();
        }
        if(this.subscribtionDelete){
            this.subscribtionDelete.unsubscribe();
        }
        if(this.deletePersonSubscription){
            this.deletePersonSubscription.unsubscribe();
        }
        if(this.submitPersonSubscription){
            this.submitPersonSubscription.unsubscribe();
        }
    }
    getPeople() {
        this.personService.index().subscribe((res: any) => {
            this.people = res.data;
        });
        this.getPositions();
    }

    submit(data): void {
        this.submitPersonSubscription = this.personService.store(data).subscribe(
            data => this.handleResponse(data),
            error => this.handleError(error, data),
        );
    }

    handleResponse(data) {
        this.personService.refreshJsonPeopleCache();
        this.positionService.refreshJsonPositionsCache()
        this.notifier.notify('success', this.peopleNotification.added);
        this.getPeople();
    }

    handleError(error, data) {
        this.personService.deleteError(data).subscribe((res: any) =>{
            console.log(res);
        });
        this.notifier.notify('error', this.peopleNotification.add_err);
        this.error = error.error.message;
        this.errorStatus = error.status;
    }

    getPositions() {
        this.positionService.index().subscribe((res: any) => {
            this.allPositions = res.data;
        })
    }

    openDialog() {
        this.openDialogService.openModal('person', AddDialogComponent, '',this.allPositions);
    }

    openDelete(slug) {
        this.openDialogService.openDeleteDialog('person', SmallDialogComponent, slug);
    }

    delete(slug){
        this.subscribtionDelete = this.personService.delete(slug).subscribe(
            data => this.handleDeleteResponse(data),
            error => this.handleDeleteError(error),
        )
    }
        
    handleDeleteResponse(data) {
        this.personService.refreshRelPeopleCache(false);
        this.subscribtionDelete.unsubscribe();
        this.notifier.notify('success', this.peopleNotification.removed);
        this.getPeople();
    }

    handleDeleteError(error) {
        this.notifier.notify('error', this.peopleNotification.remove_err);
        this.error = error.error.message;
        this.errorStatus = error.status;
    }
}