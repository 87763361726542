import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OpendialogService } from 'src/app/services/opendialog.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';


@Component({
  selector: 'app-update-dialog',
  templateUrl: './update-dialog.component.html',
  styleUrls: ['./update-dialog.component.scss']
})
export class UpdateDialogComponent implements OnInit {
  type;
  dataSlug;
  modalFormGroup: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data, 
    private dialogRef: MatDialogRef<UpdateDialogComponent>,
    private openDialogService: OpendialogService,
    private formBuilder: FormBuilder) { 
      this.type = this.data.type;
      this.dataSlug = this.data.slug;
    }

  ngOnInit(): void {
    this.modalFormGroup = this.formBuilder.group({
      typeName: [this.dataSlug.name, [Validators.required]],
    });
  }

  closeDialog(){
    this.dialogRef.close();
  }
  
  updateCompanyTypeItem(){
    this.openDialogService.updateCompanyTypeItem.next({ slug: this.dataSlug });
    this.closeDialog()
  }

  submit(): void {
    this.openDialogService.updateCompanyTypeItem.next({ typeName: this.modalFormGroup.value.typeName, slug: this.dataSlug });
    this.closeDialog();
  }

}
