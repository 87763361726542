
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, Inject, OnInit, Optional, HostListener, OnDestroy, Directive } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LoginText, NotificationText } from '../../../lang/globals-en';
import { OpendialogService } from 'src/app/services/opendialog.service';
import { CompanyTypesService } from 'src/app/services/company-types.service';
import { UploadService } from 'src/app/services/upload.service';
import { SkillService } from 'src/app/services/skill.service';

@Component({
  selector: 'app-bulk-dialog',
  templateUrl: './bulk-dialog.component.html',
  styleUrls: ['./bulk-dialog.component.scss']
})
export class BulkDialogComponent implements OnInit {
  modalFormGroup: FormGroup;
  separatorKeysCodes: number[] = [ENTER, COMMA, TAB];
  focusInput: boolean = false;
  type: any;

  @ViewChild('positionInput') positionInput: ElementRef<HTMLInputElement>;
  position = new FormControl();
  filteredPositions: Observable<any[]>;
  positions: any[] = [];
  positionValues: any[] = [];
  allPositions: any[];

  @ViewChild('skillInput') skillInput: ElementRef<HTMLInputElement>;
  skill = new FormControl();
  filteredSkills: Observable<any[]>;
  skills: any[] = [];
  skillValues: any[] = [];
  allSkills: any[];

  constructor(
    private formBuilder: FormBuilder,
    @Optional() public dialogRef: MatDialogRef<BulkDialogComponent>,
    private openDialogService: OpendialogService,
    @Inject(MAT_DIALOG_DATA) public data,
    private skillService: SkillService
  ) {
    this.type = this.data.type;
   }

  ngOnInit(): void {
    this.getPositions();
    this.getSkills();
    this.modalFormGroup = this.formBuilder.group({
      names: [null, [Validators.required]],
      person: '',
      positions: [null, null],
      skills: [null, null],
      person_id: '',
      brand_id: '',
      company_id: '',
  });

    this.modalFormGroup.get('person_id').setValue(this.data.ssData.person_id);
    this.modalFormGroup.get('brand_id').setValue(this.data.ssData.brand_id);
    this.modalFormGroup.get('company_id').setValue(this.data.ssData.company_id);
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  submit(): void {
    this.modalFormGroup.get('positions').setValue(this.positionValues);
    this.modalFormGroup.get('skills').setValue(this.skillValues);
    this.openDialogService.bulkData.next({ type: this.type, data: this.modalFormGroup.value });
    this.dialogRef.close();
  }


    // -- SKILL INPUT WITH SELECT 2 -- //
    getSkills() {
      this.allSkills = this.data.skills;
      this.filterSkills();
    }

    filterSkills() {
        this.filteredSkills = this.skill.valueChanges.pipe(
            startWith(null),
            map((skill: string | null) => (skill ? this._filterSkills(skill) : this.allSkills.slice())),
        );
    }
    
    private _filterSkills(value: string): string[] {
        const filterValue = value.toString().toLowerCase();
        return this.allSkills.filter(skills => skills.name.toString().toLowerCase().includes(filterValue));
    }

    addSkill(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (value) {
            if(!this.skills.includes(value)) {
                this.skills.push(value);
                this.skillValues.push(value);
            }
        }
        if (event.input) {
            event.input.value = '';
        }
        this.skill.setValue(null);
    }

    removeSkill(skill: string): void {
        const index = this.skills.indexOf(skill);
        const valueByName = this.skillValues.indexOf(skill);
        let valueId;
        this.allSkills.forEach(item => {
            if(skill == item.name){
                valueId = item.id
            }
        });

        if (index >= 0) {
            this.skills.splice(index, 1);
        }
        if (valueByName >= 0) {
            this.skillValues.splice(valueByName, 1);
        }
        if (valueId) {
            const arrayIndex = this.skillValues.indexOf(valueId);
            this.skillValues.splice(arrayIndex, 1);
        }
    }

    selectedSkill(event: MatAutocompleteSelectedEvent): void {
        if(!this.skills.includes(event.option.viewValue)) {
            this.skills.push(event.option.viewValue);
            this.skillValues.push(event.option.value);
        }
        this.skillInput.nativeElement.value = '';
        this.skill.setValue(null);
        this.skill.disable();
        this.skill.enable();
    }
    // -- END OF POSITION INPUT WITH SELECT 2 -- //


     // -- POSITION INPUT WITH SELECT 2 -- //
     getPositions() {
      this.allPositions = this.data.positions;
      this.filterPositions();
    }

    filterPositions() {
        this.filteredPositions = this.position.valueChanges.pipe(
            startWith(null),
            map((position: string | null) => (position ? this._filterPositions(position) : this.allPositions.slice())),
        );
    }
    
    private _filterPositions(value: string): string[] {
        const filterValue = value.toString().toLowerCase();
        return this.allPositions.filter(position => position.name.toString().toLowerCase().includes(filterValue));
    }

    addPosition(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (value) {
            if(!this.positions.includes(value)) {
                this.positions.push(value);
                this.positionValues.push(value);
            }
        }
        if (event.input) {
            event.input.value = '';
        }
        this.position.setValue(null);
    }

    removePosition(position: string): void {
        const index = this.positions.indexOf(position);
        const valueByName = this.positionValues.indexOf(position);
        let valueId;
        this.allPositions.forEach(item => {
            if(position == item.name){
                valueId = item.id
            }
        });

        if (index >= 0) {
            this.positions.splice(index, 1);
        }
        if (valueByName >= 0) {
            this.positionValues.splice(valueByName, 1);
        }
        if (valueId) {
            const arrayIndex = this.positionValues.indexOf(valueId);
            this.positionValues.splice(arrayIndex, 1);
        }
    }

    selectedPosition(event: MatAutocompleteSelectedEvent): void {
        if(!this.positions.includes(event.option.viewValue)) {
            this.positions.push(event.option.viewValue);
            this.positionValues.push(event.option.value);
        }
        this.positionInput.nativeElement.value = '';
        this.position.setValue(null);
        this.position.disable();
        this.position.enable();
    }
    // -- END OF POSITION INPUT WITH SELECT 2 -- //

}
