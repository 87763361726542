import { CommonModule } from "@angular/common";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { LoadingModuleComponent } from "./loading-module.component";

@NgModule({
    declarations: [
        LoadingModuleComponent
    ],
    imports: [
        CommonModule,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    exports: [
        LoadingModuleComponent,
    ]
})

export class LoadingModule{ 
}