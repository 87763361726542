<div id="mySidenav" class="sidenav-info" [class.show]="opened" >
    <div class="sidenav-info-wrapper" *ngIf="dataNav">  
        <div class="single-team">
            <div class="close-button" (click)="closeSideNav()">
                <img src="./../../../../../assets/img/close-button.svg" alt="close">
            </div>
            <div class="navmenu-avatar" *ngIf="(typePosition.group === 'people' || typePosition.group === 'person-key-contact') else logoTemplate">
                <div *ngIf="dataNav.avatar; else noAvatarImg">
                    <cl-image _ngcontent-ywn-2="" public-id="mypic" class="thumbnail inline"
                        ng-reflect-public-id="mypic">
                        <img src="{{ imageUrlType }}/{{ dataNav.avatar }}">
                    </cl-image>
                </div>
                <ng-template #noAvatarImg>
                    <img src="./../../../../../assets/img/default-logo.png" alt="avatar">
                </ng-template>
            </div>
            <ng-template #logoTemplate>
                <div *ngIf="dataNav.logo; else noLogoImg">
                    <div class="navmenu-logo">
                        <cl-image _ngcontent-ywn-2="" public-id="mypic" class="thumbnail inline"
                            ng-reflect-public-id="mypic">
                            <img src="{{ imageUrlType }}/{{ dataNav.logo }}">
                        </cl-image>
                    </div>
                </div>
                <ng-template #noLogoImg>
                    <div class="navmenu-logo">
                        <img src="./../../../../../assets/img/default-logo.png" alt="logo">
                    </div>
                </ng-template>
            </ng-template>
        
            <div class="navmenu-info">
                <div class="name">
                    <h3>{{ dataNav.name }}</h3>
                </div>
                <div class="position" *ngIf="(typePosition.group === 'people' || typePosition.group === 'person-key-contact')">
                    <p>Position</p>
                    <ng-container *ngIf="dataNav.positions?.length > 0; else noPosition">
                        <div  *ngFor="let position of dataNav.positions; let isLast=last; let i = index">
                            <h3>{{position.name }}{{isLast ? ' ' : ', '}}</h3>
                        </div>
                    </ng-container>
                    <ng-template #noPosition>
                        <span class="no-information">
                            No Position
                        </span>
                    </ng-template>
                </div>
                <div class="email">
                    <p>Email</p>
                    <div *ngIf="dataNav.email; else noEmail">
                        <!-- <a target="_blank" href="https://mail.google.com/mail/u/0/#inbox?to={{ dataNav.email }}&compose=new">{{ dataNav.email }}</a>  -->
                        <!-- <a target="_blank" href="https://mail.google.com/mail/u/0/#inbox?compose=new">{{ dataNav.email }}</a> -->
                        <div *ngIf="isGmail">
                            <a target="_blank" href="https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&to={{ dataNav.email }}">{{ dataNav.email }}</a> 
                        </div>
                        <div *ngIf="!isGmail">
                            <a target="_blank" href="mailto: {{ dataNav.email }}">{{ dataNav.email }}</a>
                        </div>
                    </div>
                    <ng-template #noEmail>
                        <span class="no-information">
                            No Email
                        </span>
                    </ng-template>
                </div>
                <div class="phone">
                    <p>Phone</p>
                    <div *ngIf="dataNav.phone; else noPhone">
                        <a href="tel:{{ dataNav.phone }}">{{ dataNav.phone }}</a> 
                    </div>
                    <ng-template #noPhone>
                        <span class="no-information">
                            No Phone
                        </span>
                    </ng-template>
                </div>
                <div class="website">
                    <p>Website</p>
                    <div *ngIf="dataNav.website; else noWebsite">
                        <a href="//{{ dataNav.website }}" target="_blank">{{ dataNav.website }}</a> 
                    </div>
                    <ng-template #noWebsite>
                        <span class="no-information">
                            No Website
                        </span>
                    </ng-template>
                </div>
            </div>
            <div class="navmenu-address-info">
                <div class="address">
                    <p>Adress</p>
                    <div  *ngIf="dataNav.address; else noAddress">
                        <h4>{{ dataNav.address }}</h4>
                    </div>
                    <ng-template #noAddress>
                        <span class="no-information">
                            No Adress
                        </span>
                    </ng-template>
                    <div class="country">
                        <ng-container *ngIf="dataNav.country; else noCountry">
                            <span class="flag-icon flag-icon-squared flag-icon-{{ dataNav.country.alpha2Code | lowercase }}"></span>
                            <h3>{{ dataNav.country.name}}</h3>
                        </ng-container>
                        <ng-template #noCountry>
                            <span class="no-information" style="margin-bottom: 0;">
                                No Country
                            </span>
                        </ng-template>
                    </div>
                </div>
                <div class="socials">
                    <h3>Social networks</h3>
                    <div class="social-item behance__row" *ngIf="dataNav.behance">
                        <p>Behance</p>
                        <div class="social-item__row behance">
                            <div class="icon-wrapper">
                                <div class="icon-wrapper__row">
                                    <img src="./../../../../../assets/img/behance.png" alt="Behance">
                                </div>
                            </div>
                            <a href="https://www.behance.net/{{ dataNav.behance }}" target="_blank">{{ dataNav.behance}}</a>
                        </div>
                    </div>
                    <div class="social-item linkedin__row" *ngIf="dataNav.linkedin">
                        <p>Linkedin</p>
                        <div class="social-item__row linkedin">
                            <div class="icon-wrapper">
                                <div class="icon-wrapper__row">
                                    <img src="./../../../../../assets/img/linkedin.png" alt="Linkedin">
                                </div>
                            </div>
                            <a href="https://www.linkedin.com/in/{{ dataNav.linkedin }}" target="_blank">{{ dataNav.linkedin }}</a>
                        </div>
                    </div>
                    <div class="social-item facebook__row" *ngIf="dataNav.facebook">
                        <p>Facebook</p>
                        <div class="social-item__row facebook">
                            <div class="icon-wrapper">
                                <div class="icon-wrapper__row">
                                    <img src="./../../../../../assets/img/facebook.png" alt="facebook">
                                </div>
                            </div>
                            <a href="https://www.facebook.com/{{ dataNav.linkedin }}" target="_blank">{{ dataNav.linkedin }}</a>
                        </div>
                    </div>
                    <div class="social-item instagram__row" *ngIf="dataNav.instagram">
                        <p>Instagram</p>
                        <div class="social-item__row instagram">
                            <div class="icon-wrapper">
                                <div class="icon-wrapper__row">
                                    <img src="./../../../../../assets/img/instagram.png" alt="instagram">
                                </div>
                            </div>
                            <a href="https://www.instagram.com/{{ dataNav.instagram }}" target="_blank">{{ dataNav.instagram }}</a>
                        </div>
                    </div>
                    <div class="social-item twitter__row" *ngIf="dataNav.twitter">
                        <p>Twitter</p>
                        <div class="social-item__row twitter">
                            <div class="icon-wrapper">
                                <div class="icon-wrapper__row">
                                    <img src="./../../../../../assets/img/twitter.png" alt="twitter">
                                </div>
                            </div>
                            <a href="https://twitter.com/{{ dataNav.twitter }}" target="_blank">{{ dataNav.twitter }}</a>
                        </div>
                    </div>
                    <div class="social-item vimeo__row" *ngIf="dataNav.vimeo">
                        <p>Vimeo</p>
                        <div class="social-item__row vimeo">
                            <div class="icon-wrapper">
                                <div class="icon-wrapper__row">
                                    <img src="./../../../../../assets/img/vimeo.png" alt="vimeo">
                                </div>
                            </div>
                            <a href="https://vimeo.com/{{ dataNav.vimeo }}" target="_blank">{{ dataNav.vimeo }}</a>
                        </div>
                    </div>
                    <div class="social-item pinterest__row" *ngIf="dataNav.pinterest">
                        <p>Pinterest</p>
                        <div class="social-item__row pinterest">
                            <div class="icon-wrapper">
                                <div class="icon-wrapper__row">
                                    <img src="./../../../../../assets/img/pinterest.png" alt="pinterest">
                                </div>
                            </div>
                            <a href="https://pinterest.com/{{ dataNav.pinterest }}" target="_blank">{{ dataNav.pinterest }}</a>
                        </div>
                    </div>
                    <div class="social-item youtube__row" *ngIf="dataNav.youtube">
                        <p>Youtube</p>
                        <div class="social-item__row youtube">
                            <div class="icon-wrapper">
                                <div class="icon-wrapper__row">
                                    <img src="./../../../../../assets/img/youtube.png" alt="youtube">
                                </div>
                            </div>
                            <a href="https://www.youtube.com/c/{{ dataNav.youtube }}" target="_blank">{{ dataNav.youtube }}</a>
                        </div>
                    </div>
                    <div class="social-item dribbble__row" *ngIf="dataNav.dribbble">
                        <p>Dribbble</p>
                        <div class="social-item__row dribbble">
                            <div class="icon-wrapper">
                                <div class="icon-wrapper__row">
                                    <img src="./../../../../../assets/img/dribbble.png" alt="dribbble">
                                </div>
                            </div>
                            <a href="https://www.dribbble.com/{{ dataNav.dribbble }}" target="_blank">{{ dataNav.dribbble }}</a>
                        </div>
                    </div>
                </div>
                <div class="button view-full-profile" id="open">
                    <div *ngIf="typePosition.group === 'company'; else noCompaniesGroup">
                        <a routerLink="/companies/type/{{ typePosition.type.plural | lowercase }}/{{ dataNav.slug }}" target="_blank">Show Profile</a>
                    </div>
                    <ng-template #noCompaniesGroup>
                        <a routerLink="/{{ urlSlug }}/{{ dataNav.slug }}" target="_blank">Show Profile</a>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>