import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
    selector: 'app-loading-bar',
    templateUrl: './loading-bar.component.html',
    styleUrls: ['./loading-bar.component.scss']
})
export class LoadingBarComponent implements OnInit {
    progress = 0;
    loading:any;

    constructor(public loaderService: LoaderService) {
    }

    ngOnInit(): void {
        this.loaderService.loadingPercent.subscribe((res) => {
            setTimeout(() => {
                this.progress += res;
            }, 0);
        })
        this.loaderService.resetLoader.subscribe((res) => {
            setTimeout(() => {
                this.progress = 0;
            }, 0);
        })
        this.loaderService.isLoading.subscribe((res) => {
            setTimeout(() => {
                this.loading = res;
            }, 0);
        })
    }

}
