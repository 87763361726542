import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OpendialogService } from 'src/app/services/opendialog.service';


@Component({
  selector: 'app-small-dialog',
  templateUrl: './small-dialog.component.html',
  styleUrls: ['./small-dialog.component.scss']
})
export class SmallDialogComponent implements OnInit {
    type;
    averageRate: number;
    cssRate = 0;
    prevRate = 0;
    singleIDModalData: number;
    singleNameModalData: string;
    singleClientData: string;
    isClient: boolean;
    slug;
    companyType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data, 
    private dialogRef: MatDialogRef<SmallDialogComponent>,
    private openDialogService: OpendialogService) {

        this.type = this.data.type;
        this.cssRate = this.data.rate;
        this.prevRate = this.data.prevRate;
        this.singleIDModalData =  this.data.singleModalData ? this.data.singleModalData.id : '';
        this.singleNameModalData =  this.data.singleModalData ? this.data.singleModalData.name : '';
        this.singleClientData =  this.data.singleModalData ? this.data.singleModalData : '';
        this.isClient = data.isClient;
        this.slug = data.slug;
        this.companyType = data.companyType;
    }

    ngOnInit(): void {
    }

    closeRatingModal(){
        let data = {
            rate: this.cssRate,
            prevRate: this.prevRate,
            button: 'close',
            singleIDModalData: this.singleIDModalData
        }

        this.openDialogService.reviewData.next({ type: this.type, data: data });
        this.dialogRef.close();
    }

    submitRatingModal(){
        let data = {
            rate: this.cssRate,
            prevRate: this.prevRate,
            button: 'save',
            singleIDModalData: this.singleIDModalData
        }

        this.openDialogService.reviewData.next({ type: this.type, data: data });
        this.dialogRef.close();
    }

    removeClient(){
        let data = {
            isClient: !this.isClient,
            singleClientData: this.singleClientData,
            clientStatus: 'removedClient'
        }

        this.openDialogService.clientData.next({ type: this.type, data: data });
        this.dialogRef.close();        
    }

    addClient(){
        let data = {
            isClient: !this.isClient,
            singleClientData: this.singleClientData,
            clientStatus: 'addedClient'
        }

        this.openDialogService.clientData.next({ type: this.type, data: data });
        this.dialogRef.close();
    }

    closeDialog(){
        this.dialogRef.close();
    }

    deletePerson(){
        this.openDialogService.deletePerson.next({ slug: this.slug, });
        this.closeDialog()
    }

    deleteBrand(){
        this.openDialogService.deleteBrand.next({ slug: this.slug, });
        this.closeDialog()
    }

    deleteService(){
        this.openDialogService.deleteService.next({ slug: this.slug, });
        this.closeDialog()
    }

    deleteIndustry(){
        this.openDialogService.deleteIndustry.next({ slug: this.slug, });
        this.closeDialog()
    }

    deleteCompany(){
        this.openDialogService.deleteCompany.next({ slug: this.slug, companyType: this.companyType });
        this.closeDialog()
    }

    deleteCompanyType(){
        this.openDialogService.deleteCompanyType.next({ slug: this.slug });
        this.closeDialog()
    }

    deleteCompanyTypeItem(){
        this.openDialogService.deleteCompanyTypeItem.next({ slug: this.slug });
        this.closeDialog()
    }
}
