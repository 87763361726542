import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Brand } from 'src/app/interfaces/brand';
import { BrandService } from 'src/app/services/brand.service';
import { OpendialogService } from 'src/app/services/opendialog.service';
import { NotifierService } from 'angular-notifier';
import { BrandsText } from '../../lang/globals-en';
import { AddDialogComponent } from '../dialogs/add-dialog/add-dialog.component';
import { SmallDialogComponent } from '../dialogs/small-dialog/small-dialog.component';
import { IndustryService } from 'src/app/services/industry.service';
import { SeoService } from 'src/app/services/seo.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-brands',
    templateUrl: './brands.component.html',
    styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit, OnDestroy {
    brands: Brand[];
    form: FormGroup;
    brandsNotification = BrandsText;
    error: null;
    errorStatus: null;
    private subscribtion: Subscription;
    private subscribtionDelete: Subscription;
    private deleteBrandSubscription: Subscription;
    private submitBrandSubscription: Subscription;
    allIndustries: any;
    
    constructor(
        private brandService: BrandService,
        private formBuilder: FormBuilder,
        private openDialogService: OpendialogService,
        private notifier: NotifierService,
        private industryService: IndustryService,
        private seoService: SeoService
    ) {
        this.seoService.setSeoMetaTags({title: 'Brands'});

        this.subscribtion = this.openDialogService.modalData.subscribe((res:any) => {
            if (res.type == 'brand') {
                this.submit(res.data);
            }
        })

        this.deleteBrandSubscription = this.openDialogService.deleteBrand.subscribe((res: any) => {
            this.delete(res.slug)
        })
    }

    ngOnInit(): void {
        this.getBrands();
        this.getIndustries();
        this.form = this.formBuilder.group({
            name: '',
            email: '',
            phone: '',
        });
    }

    ngOnDestroy(): void {
        if(this.subscribtion){
            this.subscribtion.unsubscribe();
        }
        if(this.subscribtionDelete){
            this.subscribtionDelete.unsubscribe();
        }
        if(this.deleteBrandSubscription){
            this.deleteBrandSubscription.unsubscribe();
        }
        if(this.submitBrandSubscription){
            this.submitBrandSubscription.unsubscribe();
        }
    }
    
    getIndustries() {
        this.industryService.index().subscribe((res: any) => {
            this.allIndustries = res.data;
        })
    }

    getBrands() {
        this.brandService.index().subscribe(
            (res: any) => this.brands = res.data
        )
        this.getIndustries();
    }

    submit(data): void {
        this.submitBrandSubscription = this.brandService.store(data).subscribe(
            data => this.handleResponse(data),
            error => this.handleError(error, data),
        );
    }

    handleResponse(data) {
        this.industryService.refreshJsonIndustriesCache();
        this.brandService.refreshJsonBrandsCache();
        this.notifier.notify('success', this.brandsNotification.added);
        this.getBrands();
    }

    handleError(error, data) {
        this.brandService.deleteError(data).subscribe((res: any) =>{
            console.log(res);
        });
        this.notifier.notify('error', this.brandsNotification.add_err);
        this.error = error.error.message;
        this.errorStatus = error.status;
    }

    openDialog() {
        this.openDialogService.openModal('brand', AddDialogComponent, '', [], [], this.allIndustries);
    }

    openDelete(slug) {
        this.openDialogService.openDeleteDialog('brand', SmallDialogComponent, slug);
    }

    delete(slug){
        this.subscribtionDelete = this.brandService.delete(slug).subscribe(
            data => this.handleDeleteResponse(data),
            error => this.handleDeleteError(error),
        )
    }
        
    handleDeleteResponse(data) {
        this.subscribtionDelete.unsubscribe();
        this.brandService.refreshJsonBrandsCache();
        this.notifier.notify('success', this.brandsNotification.removed);
        this.getBrands();
    }

    handleDeleteError(error) {
        this.notifier.notify('error', this.brandsNotification.remove_err);
        this.error = error.error.message;
        this.errorStatus = error.status;
    }
}
