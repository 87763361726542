<div style="padding-top: 100px" class="container-fluid">

    <div class="button-wrappers">
        <button (click)="openCompanyDialog()" class="new-company">Add new Company</button>
        <button (click)="openTypeDialog()" class="new-type">Add new Company types</button>
    </div>

    <div class="card">
        <div class="card-header">
            Company List
        </div>

        <div class="card-body">
            <div class="table-responsive">
                <table class=" table table-bordered table-striped table-hover datatable company-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Types</th>
                            <th> Actions </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr data-entry-id="" *ngFor='let company of companies;'>
                            <td>{{ company.id }}</td>
                            <td>{{ company.name }}</td>
                            <td>
                                <ng-container *ngFor="let item of company.types; let i = index">
                                    {{item.name}}
                                    <span *ngIf="i+1 < company.types.length">, </span>
                                </ng-container>
                            </td>
                            <td>
                                <a class="btn btn-xs btn-primary mr-2" routerLink="/companies/type/{{ company.types[0]?.slug }}/{{ company.slug }}">
                                    View
                                </a>
                                <a class="btn btn-xs btn-danger" (click)="openDelete(company.slug, company.types)">
                                    Delete
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>