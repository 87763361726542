import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '../config';
import { TokenService } from './auth/token.service';
import { CacheResolverService } from './cache-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
    config:Config;
    constructor(private http: HttpClient, 
        private token: TokenService,
        private cacheResolver: CacheResolverService) { }

    headers = new HttpHeaders({'Content-Type': 'application/json', 'Authorization':`Bearer ${this.token.get()}`});

    index(){
        return this.http.get(Config.url + 'services', {headers:this.headers});
    }
    
    getList(){
        return this.http.get(Config.url + 'services/getJson/list', {headers:this.headers});
    }

    store(data){
        return this.http.post(Config.url + 'services', data, {headers:this.headers});
    }

    add(data){
        return this.http.post(Config.url + 'services/addservice', data, {headers:this.headers});
    }

    show(slug){
        return this.http.get(Config.url + 'services/' + slug, {headers:this.headers});
    }

    getRelationships(type, slug){
        return this.http.get(Config.url + type + '/' + slug + '/relationships/services', {headers:this.headers});
    }

    deleteRelationship(slug, data){
        return this.http.post(Config.url + 'services/'+ slug +'/deleteRelationship', data, {headers:this.headers});
    }

    update(data, slug){
        return this.http.put(Config.url + 'services/' + slug, data, {headers:this.headers}); 
    }

    delete(slug){
        return this.http.delete(Config.url + 'services/' + slug, {headers:this.headers});
    }

    deleteError(data){
        return this.http.post(Config.url + 'services/data/error', data, {headers:this.headers}); 
    }

    refreshRelServicesCache(type, slug){
        this.cacheResolver.delete(Config.url + type + '/' + slug + '/relationships/services')
        this.refreshJsonServicesCache();
    }
    
    refreshJsonServicesCache(){
        this.cacheResolver.delete(Config.url + 'services/getJson/list')
    }

    refreshRelationsCache(){
        const cacheItems = { ...localStorage };
        const keysArray = Object.keys(cacheItems);
        for (let i = 0; i < keysArray.length; i++) {
            if(keysArray[i].includes('/relationships/services')){
                this.cacheResolver.delete(keysArray[i])
            }
        }
    }
}
