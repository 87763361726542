import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TokenService } from 'src/app/services/auth/token.service';
import { NotificationText, RegisterText } from '../../lang/globals-en';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
    notifications = NotificationText;
    text = RegisterText;
    form: FormGroup;
    headerText:any = this.text.header;
    hide = true;
    confirm_hide = true;
    error: null;
    errorStatus: null;

    constructor(private authService:AuthService,
                private tokenService: TokenService,
                private router: Router,
                private fb: FormBuilder,
                private notifier: NotifierService) { }

    ngOnInit(): void {
        this.form = this.fb.group({
            name: [null, [Validators.required]],
            email: [null, [Validators.required, Validators.email]],
            password: [null, [Validators.required, Validators.minLength(8)]],
            password_confirmation: [null, [Validators.required, Validators.minLength(8)]],
            terms: [null, [Validators.requiredTrue]],
        });
    }

    onSubmit(){
        this.error = null;
        this.errorStatus = null;
        return this.authService.register(this.form.value).subscribe(
            data => this.handleResponse(data),
            error => this.handleError(error)
        );
    }

    handleResponse(data) {
        this.notifier.notify('success', this.notifications.registered);
        this.tokenService.handle(data.token);
        this.router.navigateByUrl('/dashboard');
    }
    
    handleError(error) {
        this.notifier.notify('error', this.notifications.register_error);
        this.headerText = this.text.header_err;
        this.error = error.error;
        this.errorStatus = error.status;
    }
}
