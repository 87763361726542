<div class="single-header single-section active">
    <div class="header-container single-container">

        <div class="logo" *ngIf="type === 'brands' || type === 'companies' ">
            <ng-container *ngIf="typeData.logo; else noLogoImg">
                <cl-image _ngcontent-ywn-2="" public-id="mypic" class="thumbnail inline"
                    ng-reflect-public-id="mypic">
                    <img *ngIf="type === 'brands'" src="{{imageUrl.brands}}/{{ typeData.logo }}">
                    <img *ngIf="type === 'companies'" src="{{imageUrl.company}}/{{ typeData.logo }}">
                </cl-image>
            </ng-container>
            <ng-template #noLogoImg>
                <img src="./../../../../../assets/img/default-logo.png" alt="logo">
            </ng-template>
        </div>

        <div class="avatar" *ngIf="type === 'people' ">
            <ng-container *ngIf="typeData.avatar; else noLogoImg">
                <cl-image _ngcontent-ywn-2="" public-id="mypic" class="thumbnail inline"
                    ng-reflect-public-id="mypic">
                    <img src="{{imageUrl.people}}/{{ typeData.avatar }}">
                </cl-image>
            </ng-container>
            <ng-template #noLogoImg>
                <img src="./../../../../../assets/img/default-logo.png" alt="avatar">
            </ng-template>
        </div>

        <div class="content">
            <!-- Title -->
            <div class="content-header">
                <div class="title-and-rating">
                    <h2 class="title">{{ typeData.name }}</h2>
                    
                    <div class="rating">
                        <div class="averageRating">
                            <p>{{ averageRate }}</p> <img src="./../../../../../assets/img/star.svg" alt="star">
                        </div>
                        <div class="rate-start">
                            <bar-rating [(rate)]="rate" [rate]="prevRate" [max]="5" (rateChange)="clickedRate()" [theme]="'stars'"></bar-rating>
                        </div>
                    </div>

                </div>
                <div class="socials-and-settings">
                    <div class="socials">
                        <a *ngIf="typeData.behance" href="https://www.behance.net/{{ typeData.behance }}" target="_blank">
                            <img src="./../../../../../assets/img/behance.png" alt="behance">
                        </a>
                        <a *ngIf="typeData.linkedin" href="https://www.linkedin.com/in/{{ typeData.linkedin }}" target="_blank">
                            <img src="./../../../../../assets/img/linkedin.png" alt="linkedin">
                        </a>
                        <a *ngIf="typeData.facebook" href="https://www.facebook.com/{{ typeData.facebook }}" target="_blank">
                            <img src="./../../../../../assets/img/facebook.png" alt="facebook">
                        </a>
                        <a *ngIf="typeData.instagram" href="https://www.instagram.com/{{ typeData.instagram }}" target="_blank">
                            <img src="./../../../../../assets/img/instagram.png" alt="instagram">
                        </a>
                        <a *ngIf="typeData.twitter" href="https://twitter.com/{{ typeData.twitter }}" target="_blank">
                            <img src="./../../../../../assets/img/twitter.png" alt="twitter">
                        </a>
                        <a *ngIf="typeData.vimeo" href="https://vimeo.com/{{ typeData.vimeo }}" target="_blank">
                            <img src="./../../../../../assets/img/vimeo.png" alt="vimeo">
                        </a>
                        <a *ngIf="typeData.pinterest" href="https://pinterest.com/{{ typeData.pinterest }}" target="_blank">
                            <img src="./../../../../../assets/img/pinterest.png" alt="pinterest">
                        </a>
                        <a *ngIf="typeData.youtube" href="https://www.youtube.com/c/{{ typeData.youtube }}" target="_blank">
                            <img src="./../../../../../assets/img/youtube.png" alt="youtube">
                        </a>
                        <a *ngIf="typeData.dribbble" href="https://www.dribbble.com/{{ typeData.dribbble }}" target="_blank">
                            <img src="./../../../../../assets/img/dribbble.png" alt="dribbble">
                        </a>
                    </div>
                    <div class="settings">
                        <a #settingButton class="settings-button" (click)="settingsActive = !settingsActive">
                            <img src="./../../../../../assets/img/settings.png" alt="settings">
                        </a>
                        <div #settingTooltip id="settings-tooltip" class="settings-tooltip f-body-3" [ngClass]="{'active': settingsActive}">
                            <a (click)="edit()" class="edit"> Edit </a>
                            <a (click)="sendOffer()" class="send-offer disabled">Send Offer</a>
                            <a (click)="offersHistory()" class="offers-history disabled">History of offers</a>
                            <a (click)="delete()" class="delete">Delete</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="email-website">
                <!-- Email -->
                <div class="email" *ngIf="typeData?.email; else noEmail">
                    <div *ngIf="isGmail">
                        <a target="_blank" href="https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&to={{ typeData.email }}">{{ typeData.email }}</a> 
                    </div>
                    <div *ngIf="!isGmail">
                        <a target="_blank" href="mailto: {{ typeData.email }}">{{ typeData.email }}</a>
                    </div>
                </div>
                <ng-template #noEmail>
                    <h4>No Email</h4>
                </ng-template>
                
                <p> - </p>

                <!-- Website -->
                <div class="website" *ngIf="typeData?.website; else noWebsite">
                    <a href="//{{ typeData.website }}" target="_blank">{{ typeData.website }}</a> 
                </div>
                <ng-template #noWebsite>
                    <h4>No Website</h4>
                </ng-template>
            </div>

            <!-- Description -->
            <div class="description" *ngIf="typeData?.description || typeData?.country; else noDescription">
                <ng-container *ngIf="typeData?.country">
                    <span class="type">{{typeSingle}}</span> from <span class="country">{{ typeData.country.name }}</span>.
                </ng-container>
                <span class="desc-content" *ngIf="typeData?.description">{{ typeData.description }}</span>
            </div>
            <ng-template #noDescription>
                <h4 class="no-description">No Description</h4>
            </ng-template>

            <ng-container *ngIf="isClient !== undefined">
                <div class="client-container" *ngIf="isClient; else notClient">
                    <button (click)="removeClientModal()">Working with Pulla</button>
                </div>
                <ng-template #notClient>
                    <button (click)="addClientModal()" class="not-client-button">Work with Pulla</button>
                </ng-template>
            </ng-container>

        </div>

    </div>
</div>
