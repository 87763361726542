import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NotificationText } from 'src/app/components/lang/globals-en';
import { GeneralService } from 'src/app/services/general.service';
import { ServiceService } from 'src/app/services/service.service';


@Component({
    selector: 'app-ss-services',
    templateUrl: './ss-services.component.html',
    styleUrls: ['./ss-services.component.scss']
})
export class SsServicesComponent implements OnInit, AfterViewInit {
    @Input() typeData;
    @Input() type;
    notifications = NotificationText;
    openInput = false;
    separatorKeysCodes: number[] = [ENTER, COMMA, TAB];
    service = new FormControl();
    services: any[] = [];
    serviceValues: any[] = [];
    allServices: any[];
    filteredServices: Observable<any[]>;
    relationshipServices: any[] = [];
    storeData = {
        services: null,
        person_id: null,
        company_id: null,
    };
    selectedListItem: any;
    loadedContent: boolean = false;

    @ViewChild('serviceInput') serviceInput: ElementRef<HTMLInputElement>;

    constructor(
        private serviceService: ServiceService,
        private notifier: NotifierService,
        private generalService: GeneralService
    ) { }

    @HostListener('window:keyup.esc') onKeyUp() {
        this.resetInputForm();
    }

    ngOnInit(): void {
        this.getRelationshipServices()

        if(this.type == 'people'){
            this.storeData.person_id = this.typeData.id
        } else {
            this.storeData.company_id = this.typeData.id
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.getAllServicesList()
        }, 0);
    }

    getRelationshipServices(){
        this.serviceService.getRelationships(this.type, this.typeData.slug ).subscribe((res:any) => {
            this.relationshipServices = res.data;
            this.selectedListItem = res.data;
            setTimeout(() => {
                this.loadedContent = true;
            }, 100);
        })
    }

    mapServicesToSelect(){
        if(this.relationshipServices){
            this.relationshipServices.forEach(item => {
                this.services.push(item.name)
                this.serviceValues.push(item.id);
            });
        }
    }

    getAllServicesList(){
        this.serviceService.getList().subscribe((res:any) => {
            if(this.selectedListItem){
                this.allServices = res.data = res.data.filter(ar => !this.selectedListItem.find(rm => (rm.slug === ar.slug)));
            }else{
                this.allServices = res.data;
            }
            this.filterServices();
        })
    }

    filterServices() {
        this.filteredServices = this.service.valueChanges.pipe(
            startWith(null),
            map((service: string | null) => (service ? this._filter(service) : this.allServices.slice())),
        );
    }

    add(){
        this.openInput = true;
        setTimeout(() => {
            this.serviceInput?.nativeElement.focus()
        }, 0);
        this.mapServicesToSelect();
    }


    submit(){       
        var errorServices = [];
        this.storeData.services = this.services;
        this.serviceValues.forEach(element => {
            if (!this.generalService.isNumber(element) && element){
                errorServices.push(element);
            }
        });        
        this.serviceService.store(this.storeData).subscribe(
            data => this.handleResponse(data),
            error => this.handleError(error, errorServices),
        );
    }

    handleResponse(data) {
        this.serviceService.refreshRelServicesCache(this.type, this.typeData.slug);
        this.notifier.notify('success', this.notifications.relationship);
        this.getRelationshipServices();
        this.getAllServicesList();
        this.resetInputForm();
        this.loadedContent = false;
    }

    handleError(error, errorServices) {
        const dataErrorServices = {
            services: errorServices
        }
        this.serviceService.deleteError(dataErrorServices).subscribe();
        this.notifier.notify('error', this.notifications.relationship_err);
    }

    addService(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (value) {
            if(!this.services.includes(value)) {
                this.services.push(value);
                this.serviceValues.push(value);
            }
        }
        if (event.input) {
            event.input.value = '';
        }
        this.service.setValue(null);
    }

    removeService(service: any): void {
        const index = this.services.indexOf(service);
        const valueByName = this.serviceValues.indexOf(service);
        let valueId;
        this.allServices.forEach(item => {
            if(service == item.name){
                valueId = item.id
            }
        });
        if (index >= 0) {
            this.services.splice(index, 1);
        }
        if (valueByName >= 0) {
            this.serviceValues.splice(valueByName, 1);
        }
        if (valueId) {
            const arrayIndex = this.serviceValues.indexOf(valueId);
            this.serviceValues.splice(arrayIndex, 1);
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        if(!this.services.includes(event.option.viewValue)) {
            this.services.push(event.option.viewValue);
            this.serviceValues.push(event.option.value);
        }
        this.serviceInput.nativeElement.value = '';
        this.service.setValue(null);
        this.service.disable();
        this.service.enable();
    }

    private _filter(value: string): string[] {
        const filterValue = value.toString().toLowerCase();
        return this.allServices.filter(service => service.name.toString().toLowerCase().includes(filterValue));
    }

    deleteRelationship(slug){
        let typeDel;
        if (this.type == 'people') {
            typeDel = 'person'
        }else if(this.type == 'brands'){
            typeDel = 'brand'
        }else{
            typeDel = 'company'
        }
        let storeDelData = {
            custom_id: this.typeData.id,
            custom_name: typeDel,
        };
        
        this.serviceService.deleteRelationship(slug, storeDelData).subscribe(
            data => this.handleDeleteResponse(data),
            error => this.handleDeleteError(error),
        )
    }

    handleDeleteResponse(data) {
        this.serviceService.refreshRelServicesCache(this.type, this.typeData.slug);
        this.notifier.notify('success', this.notifications.relationshipDel);
        this.getRelationshipServices();
        this.getAllServicesList();
    }

    handleDeleteError(error) {
        this.notifier.notify('error', this.notifications.relationshipDel_err);
    }

    resetInputForm(){
        this.openInput = false;
        this.services = []
        this.serviceValues = []
        this.storeData.services = '';
        this.serviceInput.nativeElement.value = '';
        this.filterServices();
    }
}
