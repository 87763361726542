<div class="tooltip-wrapper" id="">   
    <div class="single-tooltip" id="no_data">
        <!-- <div [ngClass]="data.logo ? 'tooltip-logo' : 'tooltip-avatar'">
            <ng-container *ngIf="data.avatar || data.logo; else noAvatarImage">
                <img *ngIf="typePosition.group == 'brands'" src="{{imageUrl.brands}}/{{ data.logo }}">
                <img *ngIf="typePosition.group == 'people'" src="{{imageUrl.people}}/{{ data.avatar }}">
                <img *ngIf="typePosition.group == 'company'" src="{{imageUrl.brands}}/{{ data.logo }}">
            </ng-container>
            <ng-template #noAvatarImage>
                <div [ngClass]="data.logo ? 'tooltip-logo' : 'tooltip-avatar'">
                    <img src="./../../../../../assets/img/default-logo.png" alt="avatar">
                </div>
            </ng-template>
        </div> -->
        <div class="tooltip-avatar" *ngIf="(typePosition.group === 'people' || typePosition.group === 'person-key-contact') else logoTemplate">
            <div *ngIf="data.avatar; else noAvatarImg">
                <cl-image _ngcontent-ywn-2="" public-id="mypic" class="thumbnail inline"
                    ng-reflect-public-id="mypic">
                    <img src="{{ imageUrlType }}/{{ data.avatar }}">
                </cl-image>
            </div>
            <ng-template #noAvatarImg>
                <img src="./../../../../../assets/img/default-logo.png" alt="avatar">
            </ng-template>
        </div>
        <ng-template #logoTemplate>
            <div *ngIf="data.logo; else noLogoImg">
                <div class="tooltip-logo">
                    <cl-image _ngcontent-ywn-2="" public-id="mypic" class="thumbnail inline"
                        ng-reflect-public-id="mypic">
                        <img src="{{ imageUrlType }}/{{ data.logo }}">
                    </cl-image>
                </div>
            </div>
            <ng-template #noLogoImg>
                <div class="tooltip-logo">
                    <img src="./../../../../../assets/img/default-logo.png" alt="logo">
                </div>
            </ng-template>
        </ng-template>

        <div class="tooltip-info">
            <div class="name">
                <h3>
                    {{ data.name }}
                </h3>
            </div>
            <div class="position" *ngIf="(typePosition.group == 'people' || typePosition.group === 'person-key-contact') && data.positions">
                <p>Position</p>
                <ng-container *ngIf="data.positions.length > 0; else noPosition">
                    <div  *ngFor="let position of data.positions; let isLast=last; let i = index">
                        <h3>{{ position.name }}{{ isLast ? ' ' : ', ' }}</h3>
                    </div>
                </ng-container>
                <ng-template #noPosition>
                    <span class="no-information">
                        No Position
                    </span>
                </ng-template>
            </div>
            <div class="email">
                <p>Email</p>
                <div *ngIf="data.email; else noEmail">
                    <h3> {{ data.email }}</h3>
                </div>
                <ng-template #noEmail>
                    <span class="no-information">
                        No Email
                    </span>
                </ng-template>
            </div>
            <div class="phone">
                <p>Phone</p>
                <div *ngIf="data.phone; else noPhone">
                    <h3> {{ data.phone }}</h3>
                </div>
                <ng-template #noPhone>
                    <span class="no-information">
                        No Phone
                    </span>
                </ng-template>
            </div>
        </div>
        <hr>
        <div class="tooltip-address-info">
            <div class="location">
                <div class="address">
                    <h4 *ngIf="data.address; else noAddress">
                        {{ data.address }}
                    </h4>
                    <ng-template #noAddress>
                        <span class="no-information">
                            No Address
                        </span>
                    </ng-template>
                </div>
                <div class="country">
                    <ng-container *ngIf="data.country; else noCountry">
                        <span class="flag-icon flag-icon-{{ data.country.alpha2Code | lowercase }} flag-icon-squared"></span>
                        <h3>{{ data.country.alpha2Code }}</h3>
                    </ng-container>
                    <ng-template #noCountry>
                        <span class="no-information">
                            No Country
                        </span>
                    </ng-template>
                </div>
            </div>
            <div class="socials">
                <div class="swiper">
                    <div class="swiper-wrapper" style="display: flex;">
                        <div class="swiper-slide">                            
                            <div class="icon-wrapper behance" [ngClass]="data.behance ? '' : 'hidden'">
                                <a href="https://www.behance.net/{{ data.behance }}" target="_blank">
                                    <img src="./../../../../../assets/img/behance.png" alt="Behance">
                                </a>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="icon-wrapper linkedin" [ngClass]="data.linkedin ? '' : 'hidden'">
                                <a href="https://www.linkedin.com/{{ data.linkedin }}">
                                    <img src="./../../../../../assets/img/linkedin.png" alt="Linkedin">
                                </a>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="icon-wrapper facebook" [ngClass]="data.facebook ? '' : 'hidden'">
                                <a href="https://www.facebook.com/{{ data.facebook }}">
                                    <img src="./../../../../../assets/img/facebook.png" alt="Facebook">
                                </a>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="icon-wrapper instagram" [ngClass]="data.instagram ? '' : 'hidden'">
                                <a href="https://www.instagram.com/{{ data.instagram }}">
                                    <img src="./../../../../../assets/img/instagram.png" alt="Instagram">
                                </a>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="icon-wrapper twitter" [ngClass]="data.twitter ? '' : 'hidden'">
                                <a href="https://twitter.com/{{ data.twitter }}">
                                    <img src="./../../../../../assets/img/twitter.png" alt="Twitter">
                                </a>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="icon-wrapper vimeo" [ngClass]="data.vimeo ? '' : 'hidden'">
                                <a href="https://vimeo.com/{{ data.vimeo }}">           
                                    <img src="./../../../../../assets/img/vimeo.png" alt="Vimeo">                        
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-button-prev swiper-button-disabled"></div>
                <div class="swiper-button-next"></div>
            </div>
            <div class="tool-editProfile">
                <a (click)="editModal()">Edit Profile</a>
            </div>
        </div>
    </div>
</div>