import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AfterLoginService } from 'src/app/services/auth/after-login.service';
import { TypeComponent } from './companies/type/type.component';
import { DashboardComponent } from './dashboard.component';
import { IndustryComponent } from './industries/industry/industry.component';
import { ServiceComponent } from './services/service/service.component';


const routes: Routes = [
    { path: '', component: DashboardComponent, canActivate:[AfterLoginService] },
    { path: 'dashboard', component: DashboardComponent, canActivate:[AfterLoginService] },
    { path: 'companies', loadChildren: () => import('./companies/companies.module').then(m => m.CompaniesModule), canActivate:[AfterLoginService] },
    { path: 'companies/type/:type/:slug', loadChildren: () => import('./companies/company/company.module').then(m => m.CompanyModule), canActivate:[AfterLoginService] },
    { path: 'companies/type/:type', component: TypeComponent, canActivate:[AfterLoginService] },
    { path: 'people', loadChildren: () => import('./people/people.module').then(m => m.PeopleModule), canActivate:[AfterLoginService] },
    { path: 'people/:slug', loadChildren: () => import('./people/person/person.module').then(m => m.PersonModule), canActivate:[AfterLoginService] },
    { path: 'brands', loadChildren: () => import('./brands/brands.module').then(m => m.BrandsModule), canActivate:[AfterLoginService] },
    { path: 'brands/:slug', loadChildren: () => import('./brands/brand/brand.module').then(m => m.BrandModule), canActivate:[AfterLoginService] },
    { path: 'clients', loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule), canActivate:[AfterLoginService] },
    { path: 'services', loadChildren: () => import('./services/services.module').then(m => m.ServicesModule), canActivate:[AfterLoginService] },
    { path: 'services/:slug', component: ServiceComponent, canActivate:[AfterLoginService] },
    { path: 'industries', loadChildren: () => import('./industries/industries.module').then(m => m.IndustriesModule), canActivate:[AfterLoginService]},
    { path: 'industries/:slug', component: IndustryComponent, canActivate:[AfterLoginService] },
    { path: '**', pathMatch: 'full', redirectTo: '', component: DashboardComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
    
})
export class DashboardRoutingModule { }
