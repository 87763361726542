
<div class="login">
    <div class="login-container">
        <div class="login-wrapper">
            <h1 class="f-header-3">{{headerText}}</h1>
            
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <mat-form-field appearance="standard">
                    <mat-label>{{ text.email }}</mat-label>
                    <input matInput type="email" formControlName="email" #emailInput>
                    <mat-error *ngIf="(form.get('email')).hasError('required')">{{ text.email_req }}</mat-error>
                    <mat-error *ngIf="(form.get('email')).hasError('email') && !(form.get('email')).hasError('required')">{{ text.email_valid }}</mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>{{ text.password }}</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" #passwordInput>
                    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="(form.get('password')).hasError('required')">{{ text.password_req }}</mat-error>
                    <mat-error *ngIf="(form.get('password')).hasError('minlength') && !(form.get('password')).hasError('required')">{{ text.password_valid }}</mat-error>
                </mat-form-field>

                <div class="login-error f-body-1 font-pinkish-red" [ngClass]="{visible: error}" >
                    <img src="../../../../assets/img/error.png" alt="error"> <span>{{error}}</span> 
                </div>

                <div mat-dialog-actions class="button-login">
                    <button mat-raised-button class="f-sub-header-3" color="primary" type="submit" [disabled]="!form.valid">{{ text.buttonText }}</button>
                </div>

                <div class="below-button">
                    <mat-checkbox color="primary" formControlName="logged_in">{{ text.logged_in }}</mat-checkbox>
                    <a class="f-link font-light-blue" routerLink="/reset-password">{{ text.forgot }}</a>
                </div>

                <div class="sign-up">
                    <p class="f-text-1">{{ text.member }}</p>
                    <a class="f-link font-light-blue" routerLink="/register">{{ text.signup }}</a>
                </div>
            </form>
        </div>
    </div>
    <div class="image-container">
        <img src="../../../../assets/img/login-theme.png" alt="">
    </div>
</div>
