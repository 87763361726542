import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class SeoService {

    constructor(private titleService:Title) { }

    setSeoMetaTags(resp) {
        if(resp?.subtitle){
            this.titleService.setTitle(this.capitalizeFirstLetter(resp.subtitle) + ' - ' + this.capitalizeFirstLetter(resp.title) + ' • Network' );
        } else {
            this.titleService.setTitle(this.capitalizeFirstLetter(resp.title) + ' • Network' );
        }
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}
