
<ng-container *ngIf="type === 'client' && isClient">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content text-center">
                <div class="modal-body">
                    <div class="services-list--center list--center">
                        <p class="f-body-5 form-text">Remove <b>{{ singleClientData.name }}</b> as a client?</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="button-wrapper">
                        <button type="submit" id="removeClient" (click)="removeClient()" class="f-button remove-button">Remove </button>
                    </div>
                </div>
            </div>
        </div>
</ng-container>
<ng-container *ngIf="type === 'client' && !isClient">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content text-center">
            <div class="modal-body">
                <div class="services-list--center list--center" id="add-client--desc">
                    <p class="f-body-5 form-text">Add <b>{{ singleClientData.name }}</b> as a client?</p>
                </div>
            </div>
            <div class="modal-footer">
                <div class="button-wrapper">
                    <button type="submit" id="addClient" (click)="addClient()" class="f-button add-button">Add</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="type === 'review'">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content text-center">
            <div class="modal-body">
                    <p class="f-body-5 form-text">Do you want to save this <b>{{ cssRate }}</b> review for <b>{{ singleNameModalData }}</b>? </p>
            </div>
            <div class="modal-footer">
                <div class="button-wrapper">
                    <button type="button" class="f-button cancel-button" (click)="closeRatingModal()">Cancel</button>                                        
                    <button type="submit" id="addNewRating" class="f-button save-button" (click)="submitRatingModal()">Save</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>


<!-- DELETE MODALS -->
<ng-container *ngIf="type === 'person'">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content text-center">
            <div class="modal-body">
                    <p class="f-body-5 form-text">Do you want to delete this profile? </p>
            </div>
            <div class="modal-footer">
                <div class="button-wrapper">
                    <button type="button" class="f-button cancel-button" (click)="closeDialog()">Cancel</button>
                    <button type="submit" class="f-button remove-button" (click)="deletePerson()">Delete</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="type === 'brand'">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content text-center">
            <div class="modal-body">
                    <p class="f-body-5 form-text">Do you want to delete this brand? </p>
            </div>
            <div class="modal-footer">
                <div class="button-wrapper">
                    <button type="button" class="f-button cancel-button" (click)="closeDialog()">Cancel</button>
                    <button type="submit" class="f-button remove-button" (click)="deleteBrand()">Delete</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="type === 'service'">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content text-center">
            <div class="modal-body">
                    <p class="f-body-5 form-text">Do you want to delete this service? </p>
            </div>
            <div class="modal-footer">
                <div class="button-wrapper">
                    <button type="button" class="f-button cancel-button" (click)="closeDialog()">Cancel</button>
                    <button type="submit" class="f-button remove-button" (click)="deleteService()">Delete</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="type === 'industry'">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content text-center">
            <div class="modal-body">
                    <p class="f-body-5 form-text">Do you want to delete this industry? </p>
            </div>
            <div class="modal-footer">
                <div class="button-wrapper">
                    <button type="button" class="f-button cancel-button" (click)="closeDialog()">Cancel</button>
                    <button type="submit" class="f-button remove-button" (click)="deleteIndustry()">Delete</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="type === 'company'">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content text-center">
            <div class="modal-body">
                    <p class="f-body-5 form-text">Do you want to delete this company? </p>
            </div>
            <div class="modal-footer">
                <div class="button-wrapper">
                    <button type="button" class="f-button cancel-button" (click)="closeDialog()">Cancel</button>
                    <button type="submit" class="f-button remove-button" (click)="deleteCompany()">Delete</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="type === 'companyType'">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content text-center">
            <div class="modal-body">
                    <p class="f-body-5 form-text">Do you want to delete this company? </p>
            </div>
            <div class="modal-footer">
                <div class="button-wrapper">
                    <button type="button" class="f-button cancel-button" (click)="closeDialog()">Cancel</button>
                    <button type="submit" class="f-button remove-button" (click)="deleteCompanyType()">Delete</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="type === 'companyTypeItem'">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content text-center">
            <div class="modal-body">
                    <p class="f-body-5 form-text">Do you want to delete this Company Type? </p>
            </div>
            <div class="modal-footer">
                <div class="button-wrapper">
                    <button type="button" class="f-button cancel-button" (click)="closeDialog()">Cancel</button>
                    <button type="submit" class="f-button remove-button" (click)="deleteCompanyTypeItem()">Delete</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>