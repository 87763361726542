import { Component, ElementRef,ViewChild, Inject, OnInit, Optional } from '@angular/core';
import {COMMA, ENTER, TAB} from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import { OpendialogService } from 'src/app/services/opendialog.service';

@Component({
    selector: 'app-single-dialog',
    templateUrl: './single-dialog.component.html',
    styleUrls: ['./single-dialog.component.scss']
})
export class SingleDialogComponent implements OnInit {

    typeFrom = new FormControl();
    items: any[] = [];//selected position
    typeValues: any[] = [];
    allPositions: any[];
    modalFormGroup: FormGroup;
    separatorKeysCodes: number[] = [ENTER, COMMA, TAB];
    type;

    @ViewChild('dataTypeInput') dataTypeInput: ElementRef<HTMLInputElement>;

    constructor(
        private formBuilder: FormBuilder,
        private openDialogService: OpendialogService,
        @Inject(MAT_DIALOG_DATA) public data,
        @Optional() public dialogRef: MatDialogRef<SingleDialogComponent>) { 
            this.type = this.data.type;
            this.openDialogService.closeModal.subscribe(res => {
                if(res){
                    this.dialogRef.close();
                }
            })
        }

    ngOnInit(): void {
        if(this.type == 'service'){
            this.modalFormGroup = this.formBuilder.group({
                services: [null, [Validators.required]],
            });    
        }else if(this.type == 'industry'){
            this.modalFormGroup = this.formBuilder.group({
                industries: [null, [Validators.required]],
            });    
        } else {
            this.modalFormGroup = this.formBuilder.group({
                types: [null, [Validators.required]],
            });    
        }
    }

    addDataItem(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (value) {
            if(!this.items.includes(value)){
                this.items.push(value);
                this.typeValues.push(value);
            }
        }
        if (event.input) {
            event.input.value = '';
        }
        this.typeFrom.setValue(null);
    }

    removeItem(item: string): void {
        const index = this.items.indexOf(item);
        const valueByName = this.typeValues.indexOf(item);
        if (index >= 0) {
            this.items.splice(index, 1);
        }

        if (valueByName >= 0) {
            this.typeValues.splice(valueByName, 1);
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        if(!this.items.includes(event.option.viewValue)){
            this.items.push(event.option.viewValue);
            this.typeValues.push(event.option.value);
        }
        this.dataTypeInput.nativeElement.value = '';
        this.typeFrom.setValue(null);
        this.typeFrom.disable();
        this.typeFrom.enable();
    }

    submit(): void{
        if(this.type == 'service'){
            this.modalFormGroup.get('services').setValue(this.typeValues);
        } else if(this.type == 'industry'){
            this.modalFormGroup.get('industries').setValue(this.typeValues);
        } else {
            this.modalFormGroup.get('types').setValue(this.typeValues);
        }
        
        // even if you delete one value it still stays in array
        this.openDialogService.modalData.next({type: this.type, data: this.modalFormGroup.value})
        this.dataTypeInput.nativeElement.value = '';
    }
}
