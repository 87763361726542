
    <div style="padding-top: 100px" class="container-fluid">
        <div class="content">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header">
                             Dashboard
                        </div>
                        <div class="card-body">
                            <strong>Admin</strong> You are logged in!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <router-outlet></router-outlet> -->
