import { CommonModule } from "@angular/common";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { AllCustomDialogComponent } from "./all-custom-dialog.component";
import { RouterModule } from '@angular/router';
import { NgxTippyModule } from "ngx-tippy-wrapper";
import { TooltipModule } from "../../tooltip/tooltip.module";

@NgModule({
    declarations: [
        AllCustomDialogComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        TooltipModule
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],

    exports: [
        AllCustomDialogComponent,
        TooltipModule
    ]

})


export class AllCustomDialogModule{ 
}