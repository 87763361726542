<div class="single-collaborator single-section active">
    <div class="collaborator-container single-container">
        <h2 class="f-sub-title-1">Collaborators</h2>
        <div class="collaborators">
            <app-loading-module class="loader" [ngClass]="{'loaded': loadedContent}"></app-loading-module>

            <div class="collaborators-wrapper" id="collaborators-wrapper" [ngClass]="{'hidden': !loadedContent}">
                <ng-container *ngFor="let collaborator of collaborators; let i = index">
                    <div class="single-collaborator toolColleaborator" *ngIf="i < 14"
                        (click)="toggleRightSidenav(collaborator, $event)" data-tippy-delay="[350, 350]"
                        [ngxTippy]="tippyTemplate" [tippyProps]="tippyContent">
                        <button class="delete-button" (click)="deleteRelationship(collaborator.slug, $event)" >
                            <img src="./../../../../../assets/img/off_close.png" alt="delete">
                        </button>
                        <div class="collaborator-logo">
                            <ng-container *ngIf="collaborator.logo; else noLogoImg">
                                <cl-image _ngcontent-ywn-2="" public-id="mypic" class="thumbnail inline"
                                    ng-reflect-public-id="mypic">
                                    <img src="{{imageUrl}}/{{ collaborator.logo }}">
                                </cl-image>
                            </ng-container>
                            <ng-template #noLogoImg>
                                <img src="./../../../../../assets/img/default-logo.png" alt="avatar">
                            </ng-template>
                        </div>
                        <template #tippyTemplate>
                            <app-tooltip [data]="collaborator" [typePosition]="typeGroup"></app-tooltip>
                        </template>
                    </div>
                    <ng-container *ngIf="i == 14">
                        <div class="single-collaborator toolColleaborator" (click)="toggleRightSidenav(collaborator, $event)"
                            *ngIf="collaborators.length == 15; else viewAllContent" data-tippy-delay="[350, 350]"
                            [ngxTippy]="tippyTemplate" [tippyProps]="tippyContent">
                            <button class="delete-button" (click)="deleteRelationship(collaborator.slug, $event)" >
                                <img src="./../../../../../assets/img/off_close.png" alt="delete">
                            </button>
                            <div class="collaborator-logo">
                                <ng-container *ngIf="collaborator.logo; else noLogoImg">
                                    <cl-image _ngcontent-ywn-2="" public-id="mypic" class="thumbnail inline"
                                        ng-reflect-public-id="mypic">
                                        <img src="{{imageUrl}}/{{ collaborator.logo }}">
                                    </cl-image>
                                </ng-container>
                                <ng-template #noLogoImg>
                                    <img src="./../../../../../assets/img/default-logo.png" alt="avatar">
                                </ng-template>
                            </div>
                        </div>
                        <ng-template #viewAllContent>
                            <div class="more-collaborators">
                                <div class="view-all-button">
                                    <p class="collaborator-count f-sub-header-2 font-blue"> 
                                        + <span> {{ dataCount - 14}}</span>
                                    </p>
                                    <a class="view-all-collaborators" (click)="showAllListCustom()"> 
                                        <img src="./../../../../../assets/img/view-all.png" alt="View All"> 
                                    </a>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </ng-container>
                <em *ngIf="dataCount === 0" class="no-collaborators f-body-2">No Collaborators</em>

                <div class="collaborator-input" [ngClass]="{open: openInput}">
                    <mat-form-field appearance="standard">
                        <mat-label>New Collaborator</mat-label>
                        <input matInput type="text" #inputField #trigger="matAutocompleteTrigger"
                            (keyup.enter)="onInputEnter($event.target.value)" [formControl]="formControl"
                            [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" [matAutocompletePosition]="'auto'" [displayWith]="displayFn">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                {{option.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <button class="f-button font-blue add"
                    [ngClass]="{'open': openInput, 'view-all-and-add-key': collaborators && dataCount > 15}"
                    (click)="add()">+Add</button>
                <button class="f-button font-blue save" [ngClass]="{open: openInput}" (click)="submit()">Save</button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="customDialog.toogleOpen" (closed)="closePopup()">
    <app-all-custom-dialog></app-all-custom-dialog>
</div>
<!-- <app-sidenav [dataNav]="collaboratorClicked"></app-sidenav> -->