import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NotificationText } from 'src/app/components/lang/globals-en';
import { GeneralService } from 'src/app/services/general.service';
import { SkillService } from 'src/app/services/skill.service';

@Component({
    selector: 'app-ss-skills',
    templateUrl: './ss-skills.component.html',
    styleUrls: ['./ss-skills.component.scss']
})
export class SsSkillsComponent implements OnInit {

    @Input() typeData;
    @Input() type;
    notifications = NotificationText;
    openInput = false;
    separatorKeysCodes: number[] = [ENTER, COMMA, TAB];
    skill = new FormControl();
    skills: any[] = [];
    skillValues: any[] = [];
    allSkills: any[];
    filteredSkills: Observable<any[]>;
    relationshipSkills: any[] = [];
    storeData = {
        skills: null,
        person_id: null
    };
    selectedListItem: any;
    loadedContent: boolean = false;

    @ViewChild('skillInput') skillInput: ElementRef<HTMLInputElement>;

    constructor(
        private skillService: SkillService,
        private notifier: NotifierService,
        private generalService: GeneralService
    ) { }
    
    @HostListener('window:keyup.esc') onKeyUp() {
        this.resetInputForm();
    }

    ngOnInit(): void {
        this.getRelationshipSkills()
        this.storeData.person_id = this.typeData.id
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.getAllSkillsList()
        }, 0);
    }

    
    getRelationshipSkills(){
        this.skillService.getRelationships(this.type, this.typeData.slug ).subscribe((res:any) => {
            this.relationshipSkills = res.data;
            this.selectedListItem = res.data;
            setTimeout(() => {
                this.loadedContent = true;
            }, 100);
        })
    }

    mapSkillsToSelect(){
        if(this.relationshipSkills){
            this.relationshipSkills.forEach(item => {
                this.skills.push(item.name)
                this.skillValues.push(item.id);
            });
        }
    }

    getAllSkillsList(){
        this.skillService.getList().subscribe((res:any) => {
            if(this.selectedListItem){
                this.allSkills = res.data = res.data.filter(ar => !this.selectedListItem.find(rm => (rm.slug === ar.slug)));
            }else{
                this.allSkills = res.data;
            }
            this.filterSkills();
        })
    }
    
    filterSkills() {
        this.filteredSkills = this.skill.valueChanges.pipe(
            startWith(null),
            map((skill: string | null) => (skill ? this._filter(skill) : this.allSkills.slice())),
        );
    }
        
    add(){
        this.openInput = true;
        setTimeout(() => {
            this.skillInput?.nativeElement.focus()
        }, 0);
        this.mapSkillsToSelect();
    }
    
    submit(){
        var errorSkills = [];
        this.storeData.skills = this.skills;
        this.skillValues.forEach(element => {
            if (!this.generalService.isNumber(element) && element){
                errorSkills.push(element);
            }
        });        
        this.skillService.store(this.storeData).subscribe(
            data => this.handleResponse(data),
            error => this.handleError(error, errorSkills),
        );
    }
        
    handleResponse(data) {
        this.skillService.refreshRelSkillsCache(this.type, this.typeData.slug);
        this.notifier.notify('success', this.notifications.relationship);
        this.getRelationshipSkills();
        this.getAllSkillsList();
        this.resetInputForm();
        this.loadedContent = false;
    }

    handleError(error, errorSkills) {
        const dataErrorSkills = {
            skills: errorSkills
        }
        this.skillService.deleteError(dataErrorSkills).subscribe();
        this.notifier.notify('error', this.notifications.relationship_err);
    }

    addSkill(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (value) {
            if(!this.skills.includes(value)) {
                this.skills.push(value);
                this.skillValues.push(value);
            }
        }
        if (event.input) {
            event.input.value = '';
        }
        this.skill.setValue(null);
    }
    
    removeSkill(skill: any): void {
        const index = this.skills.indexOf(skill);
        const valueByName = this.skillValues.indexOf(skill);
        let valueId;
        this.allSkills.forEach(item => {
            if(skill == item.name){
                valueId = item.id
            }
        });
        if (index >= 0) {
            this.skills.splice(index, 1);
        }
        if (valueByName >= 0) {
            this.skillValues.splice(valueByName, 1);
        }
        if (valueId) {
            const arrayIndex = this.skillValues.indexOf(valueId);
            this.skillValues.splice(arrayIndex, 1);
        }
    }
       
    selected(event: MatAutocompleteSelectedEvent): void {
        if(!this.skills.includes(event.option.viewValue)) {
            this.skills.push(event.option.viewValue);
            this.skillValues.push(event.option.value);
        }
        this.skillInput.nativeElement.value = '';
        this.skill.setValue(null);
        this.skill.disable();
        this.skill.enable();
    }
    
    private _filter(value: string): string[] {
        const filterValue = value.toString().toLowerCase();
        return this.allSkills.filter(skill => skill.name.toString().toLowerCase().includes(filterValue));
    }
    
    deleteRelationship(slug){
        let storeDelData = {
            custom_id: this.typeData.id,
            custom_name: 'person',
        };
        
        this.skillService.deleteRelationship(slug, storeDelData).subscribe(
            data => this.handleDeleteResponse(data),
            error => this.handleDeleteError(error),
        )
    }

    handleDeleteResponse(data) {
        this.skillService.refreshRelSkillsCache(this.type, this.typeData.slug);
        this.notifier.notify('success', this.notifications.relationshipDel);
        this.getRelationshipSkills();
        this.getAllSkillsList();
    }

    handleDeleteError(error) {
        this.notifier.notify('error', this.notifications.relationshipDel_err);
    }

    resetInputForm(){
        this.openInput = false;
        this.skills = []
        this.skillValues = []
        this.storeData.skills = '';
        this.skillInput.nativeElement.value = '';
        this.filterSkills();
    }
}
