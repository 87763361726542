import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Config } from 'src/app/config';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
    public isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public setToken: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    
    constructor(private http: HttpClient) { }

    headers = new HttpHeaders({'Content-Type': 'application/json', 'Authorization':`Bearer ${this.get()}`});

    handle(token) {
        this.set(token);
    }

    set(token) {
        localStorage.setItem( 'token' , token);
        this.isLoggedIn.next(true);
        this.setToken.next(token);
    }
    
    get() {
        return localStorage.getItem('token');
    }
    
    remove() {
        localStorage.removeItem('token');
    }

    checkToken(data){
        return this.http.post(Config.url + 'checkToken', data, {headers:this.headers});
    }

    isValid() {
        const token = this.get();
        if (token) {
            return true;
        }
        return false;
    }

    checkIfTokenExpire(token){
        this.checkToken(token).subscribe((resp:any) => {
            if (resp.response) {
                return false;
            }
            return true;
        })
    }
    
    loggedIn() {
        return this.isValid();
    }
}
