import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotifierModule } from 'angular-notifier';



@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        NotifierModule.withConfig({
            theme: 'material',
            position: {
                horizontal: {
                    position: 'right',
                    distance: 20
                },
                vertical: {
                    position: 'top',
                    distance: 20,
                    gap: 10
                },
            },
            behaviour: {
                autoHide: 3000,
                onClick: 'hide',
                onMouseover: 'pauseAutoHide',
                showDismissButton: true,
                stacking: 4,
            },
            animations: {
                enabled: true,
                show: {
                    preset: 'slide',
                    speed: 300,
                    easing: 'ease',
                },
                hide: {
                    preset: 'fade',
                    speed: 300,
                    easing: 'ease',
                    offset: 50,
                },
                shift: {
                    speed: 300,
                    easing: 'ease',
                },
                overlap: 150,
            },
        }),
    ],
    exports: [
        CommonModule,
        NotifierModule
    ],
})
export class NotificationsModule { }
