
<ng-container *ngIf="type == 'companytypeDataItem'">
    <form [formGroup]="modalFormGroup" (submit)="submit()" id="ngForm">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content text-center">
                <div class="modal-body">
                        <p class="f-body-5 form-text">Do you want to update? </p>
                </div>
                <mat-form-field class="full-width-input" appearance="standard">
                    <mat-label class="label">Types</mat-label>
                    <input matInput type="text"  class="input" formControlName="typeName" #typeNameInput>
                </mat-form-field>
                <div class="modal-footer">
                    <div class="button-wrapper">
                        <button type="button" class="f-button cancel-button" (click)="closeDialog()">Cancel</button>            
                        <button class="f-button save-button" type="submit">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-container>