import { CommonModule } from "@angular/common";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PeopleComponent } from "./people.component";

const routes: Routes = [
    {
        path: '',
        component: PeopleComponent,
    }
]

@NgModule({
    declarations: [PeopleComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes)
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
})

export class PeopleModule {

}