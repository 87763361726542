import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TokenService } from 'src/app/services/auth/token.service';
import { CompanyTypesService } from 'src/app/services/company-types.service';
import { SmallDialogComponent } from '../dialogs/small-dialog/small-dialog.component';
import { UpdateDialogComponent } from '../dialogs/update-dialog/update-dialog.component';
import { OpendialogService } from 'src/app/services/opendialog.service';
import { Subscription } from 'rxjs';
import { NotifierService } from 'angular-notifier';
import { CompanyTypesText } from '../../lang/globals-en';
import { CompanyService } from 'src/app/services/company.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

    sideMenuClicked = false;
    isMinimized = false;
    bodyClass = document.querySelector('body');
    loggedIn = false;
    companyTypes;
    openDashboardMenu = false;
    private subscribtionDelete: Subscription;
    error: null;
    errorStatus: null;
    companyTypesNotification = CompanyTypesText;
    activeItem;

    constructor(
        private authService: AuthService,
        private router: Router,
        private token: TokenService,
        private companyTypesService: CompanyTypesService,
        private openDialogService: OpendialogService,
        private notifier: NotifierService,
        private companyService: CompanyService) {

        this.token.isLoggedIn.subscribe((res) => {
            if (res || this.token.loggedIn()) {
                this.loggedIn = true;
                this.getCompanyTypes();
            }
        })

        this.companyTypesService.refreshCompanyType.subscribe(res => {
            this.getCompanyTypes();
        })

     
    }

    ngOnInit(): void { 
        this.router.events.subscribe((val) => {
            if(val instanceof NavigationEnd){
                if(val.url.includes('/companies')){
                    this.openDashboardMenu= true; 
                } else {
                    this.openDashboardMenu= false; 
                }
            }
        });

        this.openDialogService.deleteCompanyTypeItem.subscribe((res: any) => {
            this.delete(res.slug)
        });

        this.openDialogService.updateCompanyTypeItem.subscribe((res: any) => {
            const dataName = {
                name: res.typeName
            }
            this.update(dataName, res.slug.slug);
        })
    }

    getCompanyTypes(){
        this.companyTypesService.getList(this.token.get()).subscribe((res:any) => {
            this.companyTypes = res.data
        })
    }

    onClickSideBarMenu() {
        if (this.isMinimized) {
            this.bodyClass.classList.remove("sidebar-minimized");
            this.bodyClass.classList.add("sidebar-lg-show");
            this.isMinimized = false;
            this.bodyClass.classList.remove("sidebar-lg-show");
        }
        this.sideMenuClicked = !this.sideMenuClicked;
        if (this.sideMenuClicked) {
            this.bodyClass.classList.add("sidebar-lg-show");
        }
    }

    onClickSideBarMenuMin() {
        this.isMinimized = true;
        this.sideMenuClicked = true;
        if (this.bodyClass.classList.contains('sidebar-minimized')) {
            this.sideMenuClicked = false;
        } else {
            this.sideMenuClicked = true;
            this.isMinimized = true;
        }
    }

    logout() {
        this.authService.logout();
        this.router.navigateByUrl('/login');
        this.loggedIn = false;
        this.token.isLoggedIn.next(false);
    }

    openDashboard(){
        if (this.router.url.indexOf('/companies') > -1) {
            this.openDashboardMenu = true;
        } else {
            this.openDashboardMenu = !this.openDashboardMenu
        }
    }

    openDelete(event, type){
        event.preventDefault();
        this.openDialogService.openDeleteDialog('companyTypeItem', SmallDialogComponent, type);
    }

    delete(slug){
        this.subscribtionDelete = this.companyTypesService.delete(slug).subscribe(
            data => this.handleDeleteResponse(data),
            error => this.handleDeleteError(error),
        )
    }
        
    handleDeleteResponse(data) {
        this.subscribtionDelete.unsubscribe();
        this.companyTypesService.refreshCompanyTypesCache();
        this.notifier.notify('success', this.companyTypesNotification.removed);
        this.getCompanyTypes();
    }

    handleDeleteError(error) {
        this.notifier.notify('error', this.companyTypesNotification.remove_err);
        this.error = error.error.message;
        this.errorStatus = error.status;
    }

    openUpdate(event, typeData){
        event.preventDefault();
        this.openDialogService.openDeleteDialog('companytypeDataItem', UpdateDialogComponent, typeData);
    }

    update(name,slug){
        this.subscribtionDelete = this.companyTypesService.update(name,slug).subscribe(
            data => this.handleUpdateResponse(data),
            error => this.handleUpdateError(error),
        )
        if(slug){
            this.companyService.refreshUpdateCompanyCache(slug);
        }
    }
        
    handleUpdateResponse(data) {
        this.companyTypesService.refreshCompanyTypesCache();
        this.notifier.notify('success', this.companyTypesNotification.updated);
        this.getCompanyTypes();
    }

    handleUpdateError(error) {
        this.notifier.notify('error', this.companyTypesNotification.updated_err);
        this.error = error.error.message;
        this.errorStatus = error.status;
    }

    onMouseOver(item) {
        this.activeItem = item;
    }
    
    onMouseOut() {
        this.activeItem = null;
    }
}
