import { Component, OnInit, Input, HostListener, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CompaniesText, ImageUrl, NotificationText } from 'src/app/components/lang/globals-en';
import { CompanyService } from 'src/app/services/company.service';
import { OpendialogService } from 'src/app/services/opendialog.service';
import { SidenavService } from 'src/app/services/sidenav.service';
import { map, startWith } from 'rxjs/operators';
import { AddDialogComponent } from '../../dialogs/add-dialog/add-dialog.component';
import { NotifierService } from 'angular-notifier';
import { AllListDialogComponent } from '../../dialogs/all-list-dialog/all-list-dialog.component';
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import { GeneralService } from 'src/app/services/general.service';
import { CustomDialogService } from 'src/app/services/custom-dialog.service';

@Component({
    selector: 'app-ss-collaborators',
    templateUrl: './ss-collaborators.component.html',
    styleUrls: ['./ss-collaborators.component.scss']
})
export class SsCollaboratorsComponent implements OnInit {
    @Input() typeData;
    @Input() collaborator;

    dataCount;
    collaborators = [];
    loadedContent: boolean = false;
    openInput: boolean = false;
    imageUrl = ImageUrl.company;
    private subscribtion: any;
    private storeSubscribtion: any;
    private updateSubscribtion: any;
    private updateModalSubscribtion: any;
    formControl = new FormControl();
    storeData = {
        name: null,
        slug: null,
        collaborator_id: null,
        companyTypes: null,
        exists: false,
    };
    isCollaboratorClicked: boolean;
    routeActive: string;
    filteredOptions: Observable<any>;
    options;
    selectedListItem: any;
    notifications = NotificationText;
    companyNotifications = CompaniesText;
    typeGroup = {
        group: 'collaborators',
        type: {
            name: 'Collaborator',
            plural: "collaborators"
        }
    };
    tippyContent: NgxTippyProps = {
        allowHTML: true,
        interactive: true,
    };
    @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
    @ViewChild("inputField") inputField: ElementRef;
    isEmailGmail;
    allDataModal;
    showAllListCustomIsOpen = false;

    constructor(private companyService: CompanyService,
        private openDialogService: OpendialogService,
        private sidenavService: SidenavService,
        private route: ActivatedRoute,
        private notifier: NotifierService,
        private generalService: GeneralService,
        public customDialog: CustomDialogService) {

        this.subscribtion = this.openDialogService.modalData.subscribe((res: any) => {
            if (res.type == 'collaborator') {
                this.submit(res.data);
            }
        })

        this.customDialog.customModalisOpen.subscribe((res: any)=> {
            this.showAllListCustomIsOpen = res;
        });

        this.updateModalSubscribtion = this.openDialogService.updateModalData.subscribe((res: any) => {
            if (res.type.group == "collaborators") {
                this.updateCollaborator(res.slug, res.data);
                if(this.showAllListCustomIsOpen){
                    this.showAllListCustom();
                }
            }
        })
    }

    @HostListener('window:keyup.esc') onKeyUp() {
        this.resetInputForm();
    }

    ngOnInit(): void {
        this.getCollaborators();
        this.storeData.collaborator_id = this.typeData.id
        this.storeData.companyTypes = [this.collaborator.name]
        this.sidenavService.cast.subscribe(data => this.isCollaboratorClicked = data);
        this.routeActive = this.route.snapshot.params.slug;
    }

    ngOnDestroy(): void {
        if (this.subscribtion) {
            this.subscribtion.unsubscribe();
        }
        if (this.storeSubscribtion) {
            this.storeSubscribtion.unsubscribe();
        }
        if (this.updateSubscribtion) {
            this.updateSubscribtion.unsubscribe();
        }
        if (this.updateModalSubscribtion) {
            this.updateModalSubscribtion.unsubscribe();
        }
    }

    getCollaborators() {
        this.companyService.getCollaboratorsLimitData(this.typeData.slug).subscribe((res: any) => {
            this.dataCount = res.allDataCount;
            this.collaborators = res.data;
            setTimeout(() => {
                this.loadedContent = true;
            }, 100);
        })
    }

    listItems() {
        this.companyService.getListByType(this.collaborator.slug).subscribe((res: any) => {
            if (this.selectedListItem) {
                this.options = res.filter(ar => !this.selectedListItem.find(rm => (rm.slug === ar.slug)));
                this.options = this.options.filter(element => { return element.id !== this.typeData.id });
            } else {
                this.options = res;
                this.options = this.options.filter(element => { return element.id !== this.typeData.id });
            }
            this.filterOptions();
        })
    }

    add() {
        if(this.collaborators?.length === 16){
            this.companyService.getCollaboratorsAllData(this.typeData.slug).subscribe((res:any) => {
                this.selectedListItem = res.data
                this.openInput = true;
                setTimeout(() => {
                    this.inputField?.nativeElement.focus()
                }, 0);
                this.listItems();
            });
        } else {
            this.selectedListItem = this.collaborators
            this.openInput = true;
            setTimeout(() => {
                this.inputField?.nativeElement.focus()
            }, 0);
            this.listItems();
        }
    }

    submit(data = null) {
        let submitData;
        if(data){
            submitData = data;
        } else {
            this.storeData.name = this.formControl.value.name ? this.formControl.value.name : this.formControl.value;
            if(this.formControl.value.name){
                this.storeData.exists = true;
                this.storeData.slug = this.formControl.value.slug;
            } else {
                this.storeData.exists = false;
                this.storeData.slug = null;
            }
            submitData = this.storeData
        }

        if(submitData){
            const nameData = {
                name: submitData.name
            }
            this.storeSubscribtion = this.companyService.store(submitData).subscribe(
                data => this.handleResponse(data),
                error => this.handleError(error, nameData),
            );
        } else {
            this.inputField.nativeElement.focus();
        }
    }

    handleResponse(data) {
        this.companyService.refreshRelCollaborators(this.typeData.slug, this.collaborator.slug);
        this.notifier.notify('success', this.notifications.relationship);
        this.getCollaborators();
        this.listItems();
        this.resetInputForm();
        this.storeSubscribtion.unsubscribe();
        if(data.slug){
            this.companyService.refreshRelCollaborators(data.slug, this.collaborator.slug);
        }
    }

    handleError(error, data) {
        this.companyService.deleteError(data).subscribe();
        this.notifier.notify('error', this.notifications.relationship_err);
        this.storeSubscribtion.unsubscribe()
    }

    updateCollaborator(id, data) {
        this.companyService.refreshRelCollaborators(this.typeData.slug, this.collaborator.slug);
        this.updateSubscribtion = this.companyService.update(id, data).subscribe(
            data => this.handleUpdateResponse(data),
            error => this.handleUpdateError(error),
        );
    }

    handleUpdateResponse(data) {
        this.notifier.notify('success', this.companyNotifications.updated);
        this.getCollaborators();
        this.listItems();
        this.companyService.refreshCompanyCache(data.data.slug);
        this.updateSubscribtion.unsubscribe()
    }
    
    handleUpdateError(error) {
        this.notifier.notify('error', this.companyNotifications.update_err);
        this.updateSubscribtion.unsubscribe()
    }

    filterOptions() {
        this.filteredOptions = this.formControl.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value?.name)),
            map(name => (name ? this._filter(name) : this.options.slice())),
        );
    }

    displayFn(collaborator): string {
        return collaborator && collaborator.name ? collaborator.name : '';
    }

    private _filter(name: string) {
        const filterValue = name.toLowerCase();
        return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    onInputEnter(e) {
        if (this.formControl.value) {
            this.storeData.name = this.formControl.value.slug ? this.formControl.value.slug : this.formControl.value;
            if (this.formControl.value.slug) {
                this.submit();
            } else {
                this.openDialogService.openModal('collaborator', AddDialogComponent, this.storeData);
                this.resetInputForm()
            }
        }
    }

    showAllList(){
        this.companyService.getCollaboratorsAllData(this.typeData.slug).subscribe((res:any) => {
            this.openDialogService.openAllListModal(this.typeGroup, AllListDialogComponent, res.data); 
        });
    }

    showAllListCustom(){
        this.companyService.getCollaboratorsAllData(this.typeData.slug).subscribe((res:any) => {
            this.allDataModal = res.data;
            if(res.data){
                setTimeout(() => {
                    this.customDialog.open();
                }, 200);
            }
            this.customDialog.customModalData.next({data:  res.data, type: this.typeGroup});
        });
    }

    toggleRightSidenav(company, event) {
        event.stopPropagation();
        let typeGroupDynamic = {
            group: 'company',
            type: {
                name: this.collaborator.name,
                plural: this.collaborator.plural
            }
        }

        const emailData = {
            email: company.email
        }
        if(company.email){
            this.generalService.checkEmailDns(emailData).subscribe((res: any) => {
                this.isEmailGmail = res;
                this.sidenavService.sidenavData.next({data: company, type: typeGroupDynamic, isGmail: this.isEmailGmail});
                this.sidenavService.open();
            })
        }else{
            this.isEmailGmail = false;
            this.sidenavService.sidenavData.next({data: company, type: typeGroupDynamic, isGmail: this.isEmailGmail});
            this.sidenavService.open();
        }
    }

    resetInputForm() {
        this.openInput = false;
        this.storeData.name = '';
        this.autocomplete.closePanel();
        this.formControl.setValue(null);
    }

    deleteRelationship(slug, event){
        event.stopPropagation();
        let type = 'collaborators';
        
        this.companyService.refreshRelCollaborators(this.typeData.slug, this.collaborator.slug);
        this.companyService.refreshRelCollaborators(slug, this.collaborator.slug);

        this.companyService.removeRelationship(slug, type, this.typeData.id).subscribe (
            data => this.handleDeleteRelationshipResponse(data),
            error => this.handleDeleteRelationshipError(error),
        )
    }

    handleDeleteRelationshipResponse(data){
        this.notifier.notify('success', this.notifications.relationshipDel);
        this.getCollaborators();
        this.listItems();
    }
    
    handleDeleteRelationshipError(error){
        this.notifier.notify('error', this.notifications.relationshipDel_err);
    }

    closePopup() {
        this.customDialog.close();
    }
}
