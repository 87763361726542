    <div style="padding-top: 100px" class="container-fluid">
        
        <div class="card">
            <div class="card-header">
                Client List
            </div>
        
            <div class="card-body">
                <div class="table-responsive">
                    <table class=" table table-bordered table-striped table-hover datatable client-table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Type</th>
                                <th>From</th>
                                <th> Actions </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr data-entry-id="" *ngFor='let client of clients; index as i'>
                                <td>{{ client.id }}</td>
                                <td>{{ client.client.name }}</td>
                                <td>{{ client.client.email }}</td>
                                <td>{{ client.type.type.name | titlecase }}</td>
                                <td>{{ client.user.name }}</td>
                                <td>
                                    <ng-container *ngIf="client.type.group === 'company'; else noCompaniesGroup">
                                        <a class="btn btn-xs btn-primary mr-2" routerLink="/companies/type/{{ client.type.type.slug | lowercase }}/{{ client.client.slug }}">View</a>
                                    </ng-container>
                                    <ng-template #noCompaniesGroup>
                                        <a class="btn btn-xs btn-primary mr-2" routerLink="/{{ client.type.group }}/{{ client.client.slug }}">View</a>
                                    </ng-template>
                                    <a class="btn btn-xs btn-danger" (click)="delete(client.id)">
                                        Delete
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
