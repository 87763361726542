import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { sha1 } from 'js-sha1';
import { from, Observable } from 'rxjs';

import * as sha1 from 'js-sha1';

@Injectable({
  providedIn: 'root'
})
export class UpdateImageService {
  private readonly cloudName = 'dkkfi0ryp';
  private readonly apiKey = '969332735454396';
  private readonly apiSecret = 'xP6h2aYdYSTgGwViDTgBECm5ZXk';

  constructor(private readonly http: HttpClient) {}

  makeImagePublic(publicId: string): Observable<any> {
    const timestamp = Date.now().toString();
    const signature = this.generateSignature(timestamp);

    const url = `https://api.cloudinary.com/v1_1/${this.cloudName}/image/upload`;
    const body = {
      public_id: publicId,
      type: 'upload',
      access_mode: 'public'
    };
    const headers = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Signature': signature,
      'X-Timestamp': timestamp
    };

    return this.http.post(url, body, { headers });
  }

  generateSignature(timestamp: string): string {
    const data = `timestamp=${timestamp}${this.apiSecret}`;
    return sha1(data);
  }

  deleteImage(publicId: string): Observable<any> {
    const timestamp = Date.now().toString();
    const signature = this.generateSignature(timestamp);

    const url = `https://api.cloudinary.com/v1_1/${this.cloudName}/image/destroy`;
    const body = {
      public_id: publicId
    };
    const headers = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Signature': signature,
      'X-Timestamp': timestamp
    };

    return this.http.post(url, body, { headers });
  }
}
