import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BeforeLoginService } from 'src/app/services/auth/before-login.service';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
    { path: '', component: LoginComponent, canActivate:[BeforeLoginService] },
    { path: 'login', component: LoginComponent, canActivate:[BeforeLoginService] },
    { path: 'register', component: RegisterComponent, canActivate:[BeforeLoginService] },
    { path: 'reset-password', component: ResetPasswordComponent, canActivate:[BeforeLoginService] },
    { path: 'change-password', component: ChangePasswordComponent, canActivate:[BeforeLoginService] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
