import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NotificationText, ResetText } from '../../lang/globals-en';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    notifications = NotificationText;
    text = ResetText;
    form: FormGroup;
    error: null;
    errorStatus: null;

    constructor(private authService: AuthService,
                private fb: FormBuilder,
                private notifier: NotifierService) { }

    ngOnInit(): void {
        this.form = this.fb.group({
            email: [null, [Validators.required, Validators.email]],
        });
    }

    onSubmit() {
        return this.authService.resetPassword(this.form.value).subscribe(
            data => this.handleResponse(data),
            error => this.handleError(error)
        );
    }

    handleResponse(data) {
        this.notifier.notify('success', this.notifications.reseted);
    }

    handleError(error) {
        this.notifier.notify('error', this.notifications.reset_error);
        this.error = error.error;
        this.errorStatus = error.status;
    }

}
