import { Component, OnInit } from '@angular/core';
import { ClientService } from 'src/app/services/client.service';
import { Client } from 'src/app/interfaces/client';
import { SeoService } from 'src/app/services/seo.service';

@Component({
    selector: 'app-clients',
    templateUrl: './clients.component.html',
    styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

    clients: Client[];

    constructor(private clientService: ClientService,
        private seoService: SeoService) { 
        this.seoService.setSeoMetaTags({title: 'Clients'});
    }

    ngOnInit(): void {
        this.getClients();
    }

    getClients() {
        this.clientService.index().subscribe(
            (res: any) => {
                this.clients = res.data;
            }
        )
    }

    delete(client) {
        if (confirm('Are you sure you want to delete this client?')) {
            this.clientService.delete(client).subscribe(res => {
                this.getClients();
            }
            )
        }
    }

}
