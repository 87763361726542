import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '../config';
import { TokenService } from './auth/token.service';
import { CacheResolverService } from './cache-resolver.service';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class KeyContactsService {

    public refreshKeyContacts: Subject<any> = new Subject();
    constructor(private http: HttpClient, private token: TokenService,
        private cacheResolver: CacheResolverService) { }

    headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.token.get()}` });

    index() {
        return this.http.get(Config.url + 'key_contacts', { headers: this.headers });
    }

    getContactsByTypeLimitData(type, id) {
        return this.http.get(Config.url + 'key_contacts/getJson/' + type + '/id/' + id + '/limitData', { headers: this.headers });
    }

    getContactsByTypeAllData(type, id) {
        return this.http.get(Config.url + 'key_contacts/getJson/' + type + '/id/' + id + '/allData', { headers: this.headers });
    }

    store(data) {
        return this.http.post(Config.url + 'key_contacts', data, { headers: this.headers });
    }

    deleteError(data){
        return this.http.post(Config.url + 'key_contacts/data/error', data, {headers:this.headers}); 
    }
        
    removeRelationship(slug, params, id){
        return this.http.post(Config.url + 'key_contacts/' + slug + '/relationships/' + params + '/detach/' + id, params, {headers:this.headers});
    }

    refreshKeyContactsCache(type, id) {
        this.cacheResolver.delete(Config.url + 'key_contacts/getJson/' + type + '/id/' + id + '/limitData')
        this.cacheResolver.delete(Config.url + 'key_contacts/getJson/' + type + '/id/' + id + '/allData')
    }
}
