<div class="change">
    <div class="change-container">
        <div class="change-wrapper">
            <h1 class="f-header-3">{{ text.header_change }}</h1>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <mat-form-field appearance="standard">
                    <mat-label>{{ text.password }}</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" #passwordInput>
                    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="(form.get('password')).hasError('required')">{{ text.password_req }}</mat-error>
                    <mat-error *ngIf="(form.get('password')).hasError('minlength') && !(form.get('password')).hasError('required')">{{ text.password_valid }}</mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>{{ text.c_password }}</mat-label>
                    <input matInput [type]="confirm_hide ? 'password' : 'text'" formControlName="password_confirmation" #passwordConfirmationInput>
                    <mat-icon matSuffix (click)="confirm_hide = !confirm_hide">{{confirm_hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="(form.get('password_confirmation')).hasError('required')">{{ text.c_password_req }}</mat-error>
                    <mat-error *ngIf="(form.get('password_confirmation')).hasError('minlength') && !(form.get('password_confirmation')).hasError('required')">{{ text.c_password_valid }}</mat-error>
                </mat-form-field>

                <div class="change-error f-body-1 font-pinkish-red" [ngClass]="{visible: error}" >
                    <img src="../../../../assets/img/error.png" alt="error"> <span>{{error}}</span> 
                </div>

                <div mat-dialog-actions class="button-change">
                    <button mat-raised-button class="f-sub-header-3" color="primary" type="submit" [disabled]="!form.valid">{{ text.buttonText_change }}</button>
                </div>
            </form>
        </div>
    </div>
    <div class="image-container">
        <img src="../../../../assets/img/login-theme.png" alt="">
    </div>
</div>