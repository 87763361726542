import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/services/service.service';
import { Service } from 'src/app/interfaces/service';

@Component({
    selector: 'app-service',
    templateUrl: './service.component.html',
    styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {
    slug;
    service: Service;

    constructor(private serviceService: ServiceService,
        private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.slug = this.route.snapshot.params.slug;
        this.serviceService.show(this.slug).subscribe((res: any) => {
            this.service = res.data;
        });
    }
}
