import { Injectable } from '@angular/core';
import { Config } from '../config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { TokenService } from './auth/token.service';
import { CacheResolverService } from './cache-resolver.service';
import { IndustryService } from './industry.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrandService {
    config:Config;
    public reloadBrandIndustries: Subject<any> = new Subject();

    constructor(private http: HttpClient,
                private token: TokenService,
                private cacheResolver: CacheResolverService,
                private industryService: IndustryService) { }

    headers = new HttpHeaders({'Content-Type': 'application/json', 'Authorization':`Bearer ${this.token.get()}`});

    index(){
        return this.http.get(Config.url + 'brands', {headers:this.headers});
    }

    getList(){
        return this.http.get(Config.url + 'brands/getJson/list', {headers:this.headers});
    }

    store(data){
        return this.http.post(Config.url + 'brands', data, {headers:this.headers});
    }

    show(slug){
        return this.http.get(Config.url + 'brands/' + slug, {headers:this.headers});
    }

    getRelationships(type, slug){
        return this.http.get(Config.url + type + '/' + slug + '/relationships/brands', {headers:this.headers});
    }

    getRelationshipsAllData(type, slug){
        return this.http.get(Config.url + type + '/' + slug + '/relationships/brands/alldata', {headers:this.headers});
    }
    
    getRelationshipsSingleData(type, brand, params, slug){
        return this.http.get(Config.url + type + '/' + brand + '/relationships/' + params + '/' + slug + '/singleData', {headers:this.headers});
    }

    getRelationshipsLimitData(type, slug){
        return this.http.get(Config.url + type + '/' + slug + '/relationships/brands/limitData', {headers:this.headers});
    }
    
    isClient(slug){
        return this.http.get(Config.url + 'brands/' + slug + '/getJson/client', {headers:this.headers});
    }

    removeClient(slug){
        return this.http.delete(Config.url + 'brands/' + slug + '/getJson/client', {headers:this.headers});
    }

    update(slug, data){
        return this.http.put(Config.url + 'brands/' + slug, data, {headers:this.headers}); 
    }

    delete(slug){
        return this.http.delete(Config.url + 'brands/' + slug, {headers:this.headers}); 
    }

    removeRelationship(slug, params, id){
        return this.http.post(Config.url + 'brands/' + slug + '/relationships/' + params + '/detach/' + id, params, {headers:this.headers});
    }

    refreshRelBrandsCache(){
        // this.cacheResolver.delete(Config.url + type + '/' + slug + '/relationships/brands/limitData')
        // this.cacheResolver.delete(Config.url + type + '/' + slug + '/relationships/brands/alldata')
        this.refreshRelationsCache();
        this.refreshJsonBrandsCache();

        // if(industryChanged){
            this.industryService.refreshJsonIndustriesCache();
            this.industryService.refreshRelationsCache();
        // }
    }

    refreshTeamCache(type, brandSlug){
        this.cacheResolver.delete(Config.url + 'brands' + '/' + brandSlug + '/relationships/'+ type +'/limitData')
    }

    refreshCompaniesCache(type, brandSlug){
        this.cacheResolver.delete(Config.url + 'brands' + '/' + brandSlug + '/relationships/companie/'+ type +'/limitData')
    }

    refreshJsonBrandsCache(){
        this.cacheResolver.delete(Config.url + 'brands/getJson/list')
    }

    refreshClientCache(slug){
        this.cacheResolver.delete(Config.url + 'brands/' + slug + '/getJson/client')
    }

    refreshBrandCache(slug){
        this.cacheResolver.delete(Config.url + 'brands/' + slug)
    }

    deleteError(data){
        return this.http.post(Config.url + 'brands/data/error', data, {headers:this.headers}); 
    }

    refreshRelationsCache(){
        const cacheItems = { ...localStorage };
        const keysArray = Object.keys(cacheItems);
        for (let i = 0; i < keysArray.length; i++) {
            if(keysArray[i].includes('/relationships/brands')){
                this.cacheResolver.delete(keysArray[i])
            }
        }
    }
}
