<div class="all-list-dialog">
    <div class="all-list-dialog-header">
        <h3 mat-dialog-title>{{ type.type.plural | titlecase }}</h3>
        <button mat-dialog-close matDialogClose class="close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div mat-dialog-content class="all-list-dialog-content">
        <div class="body-content">
            <div class="all-data">
                <ng-container *ngFor="let data of allData; let i = index">
                    <div class="single-data" (click)="openRightSidenav(data, $event)" [ngxTippy]="tippyTemplate"  data-tippy-delay="[350, 350]" [tippyProps]="tippyContent">
                        <div *ngIf="(type.group === 'people' || type.group === 'person-key-contact') else logoTemplate">
                            <div *ngIf="data.avatar; else noAvatarImg">
                                <div  class="data-logo avatar" >
                                    <img src="{{ imageUrlType }}/{{ data.avatar }}">
                                </div>
                            </div>
                            <ng-template #noAvatarImg>
                                <div  class="data-logo avatar">
                                    <img src="./../../../../../assets/img/default-logo.png" alt="avatar">
                                </div>
                            </ng-template>
                        </div>
                        <ng-template #logoTemplate>
                            <div *ngIf="data.logo; else noLogoImg">
                                <div class="data-logo">
                                    <img src="{{ imageUrlType }}/{{ data.logo }}">
                                </div>
                            </div>
                            <ng-template #noLogoImg>
                                <div class="data-logo">
                                    <img src="./../../../../../assets/img/default-logo.png" alt="logo">
                                </div>
                            </ng-template>
                        </ng-template>
                        <div class="data-name">
                            <h3 class="f-sub-header-1">{{ data.name }}</h3>
                        </div>
                        <template #tippyTemplate>
                            <app-tooltip [data]="data" [typePosition]="typeGroup"></app-tooltip>
                        </template>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<app-sidenav [dataNav]="itemClicked" [typePosition]="typeGroup"></app-sidenav>



