import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '../config';
import { TokenService } from './auth/token.service';
import { CacheResolverService } from './cache-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
    config:Config;
    constructor(private http: HttpClient, 
            private token: TokenService,
            private cacheResolver: CacheResolverService) { }

    headers = new HttpHeaders({'Content-Type': 'application/json', 'Authorization':`Bearer ${this.token.get()}`});

    index(){
        return this.http.get(Config.url + 'clients', {headers:this.headers});
    }
    
    show(id){
        return this.http.get(Config.url + 'clients/' + id, {headers:this.headers});
    }

    // store(type, id){
    //     return this.http.post(Config.url + 'clients/' + type + '/' + id + '/addclient', {headers:this.headers});
    // }

    store(data){
        return this.http.post(Config.url + 'clients', data , {headers:this.headers});
    }

    delete(id){
        return this.http.delete(Config.url + 'clients/' + id, {headers:this.headers});
    }

    // refreshKeyContactsCache(type, id) {
    //     this.cacheResolver.delete(Config.url + 'key_contacts/getJson/' + type + '/id/' + id + '/limitData')
    // }
}
