import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Config } from '../config';
import { TokenService } from './auth/token.service';
import { CacheResolverService } from './cache-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class IndustryService {
    config: Config;
    public reloadIndustries: Subject<any> = new Subject();

    constructor(private http: HttpClient, 
        private token: TokenService,
        private cacheResolver: CacheResolverService) { }

    headers = new HttpHeaders({'Content-Type': 'application/json', 'Authorization':`Bearer ${this.token.get()}`});

    index(){
        return this.http.get(Config.url + 'industries', {headers:this.headers});
    }
    
    getList(){
        return this.http.get(Config.url + 'industries/getJson/list', {headers:this.headers});
    }

    store(data){
        return this.http.post(Config.url + 'industries', data, {headers:this.headers});
    }

    add(data){
        return this.http.post(Config.url + 'industries/addindustry', data, {headers:this.headers});
    }

    show(slug){
        return this.http.get(Config.url + 'industries/' + slug, {headers:this.headers});
    }

    getRelationships(type, slug){
        return this.http.get(Config.url + type + '/' + slug + '/relationships/industries', {headers:this.headers});
    }

    deleteRelationship(slug, data){
        return this.http.post(Config.url + 'industries/'+ slug +'/deleteRelationship', data, {headers:this.headers});
    }

    update(data, slug){
        return this.http.put(Config.url + 'industries/' + slug, data, {headers:this.headers}); 
    }

    delete(slug){
        return this.http.delete(Config.url + 'industries/' + slug, {headers:this.headers});
    }

    deleteError(data){
        return this.http.post(Config.url + 'industries/data/error', data, {headers:this.headers}); 
    }

    refreshRelIndustriesCache(type, slug){
        this.cacheResolver.delete(Config.url + type + '/' + slug + '/relationships/industries')
        this.refreshJsonIndustriesCache();
    }
    
    refreshJsonIndustriesCache(){
        this.reloadIndustries.next(true);
        this.cacheResolver.delete(Config.url + 'industries/getJson/list')
        this.cacheResolver.delete(Config.url + 'industries')
    }

    refreshRelationsCache(){
        const cacheItems = { ...localStorage };
        const keysArray = Object.keys(cacheItems);
        for (let i = 0; i < keysArray.length; i++) {
            if(keysArray[i].includes('/relationships/industries')){
                this.cacheResolver.delete(keysArray[i])
            }
        }
    }

    refreshPeopleIndustry(data){
        data.forEach(item => {
            const person = item.slug;
            this.cacheResolver.delete(Config.url + 'people/'+ person +'/relationships/industries');
        });
    }
}
