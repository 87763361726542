import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '../config';
import { TokenService } from './auth/token.service';
import { CacheResolverService } from './cache-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {
    config:Config;
    constructor(
        private http: HttpClient, 
        private token: TokenService,
        private cacheResolver: CacheResolverService) { }

    headers = new HttpHeaders({'Content-Type': 'application/json', 'Authorization':`Bearer ${this.token.get()}`});

    index(){
        return this.http.get(Config.url + 'reviews', {headers:this.headers});
    }

    store(data){
        return this.http.post(Config.url + 'reviews', data, {headers:this.headers});
    }

    getAvarage(params, id){
        return this.http.get(Config.url + 'reviews/' + params + '/' + id + '/' +'average', {headers:this.headers});
    }

    getUserReview(params, id){
        return this.http.get(Config.url + 'reviews/' + params + '/' + id + '/' + 'user', {headers:this.headers});
    }

    show(id){
        return this.http.get(Config.url + 'reviews/' + id, {headers:this.headers});
    }

    refreshReviewCache(params, id){
        this.cacheResolver.delete(Config.url + 'reviews/' + params + '/' + id + '/' +'average')
        this.cacheResolver.delete(Config.url + 'reviews/' + params + '/' + id + '/' + 'user')
    }
}
