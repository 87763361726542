import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { IndustryComponent } from './industries/industry/industry.component';
import { AddDialogComponent } from './dialogs/add-dialog/add-dialog.component';
import { EditDialogComponent } from './dialogs/edit-dialog/edit-dialog.component';
import { ServiceComponent } from './services/service/service.component';
import { NgMaterialModule } from 'src/app/ng-material/ng-material.module';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SingleDialogComponent } from './dialogs/single-dialog/single-dialog.component';
import { TypeComponent } from './companies/type/type.component';
import { BrowserModule } from '@angular/platform-browser';

import { FilePondModule, registerPlugin } from 'ngx-filepond';
import * as FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import * as FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import * as FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import * as FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import * as FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import * as FilePondPluginImageResize from 'filepond-plugin-image-resize';
import * as FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import { SmallDialogComponent } from './dialogs/small-dialog/small-dialog.component';
import { PeopleModule } from './people/people.module';
import { BrandsModule } from './brands/brands.module';
import { ServicesModule } from './services/services.module';
import { AllListDialogComponent } from './dialogs/all-list-dialog/all-list-dialog.component';
import { SingleSharedModule } from './single-sections/single-shared.module';
import { CompaniesModule } from './companies/companies.module';
import { ClientsModule } from './clients/clients.module';
import { LoadingModule } from './loading-module/loading-module.module';
import { RouterModule } from '@angular/router';
import { UpdateDialogComponent } from './dialogs/update-dialog/update-dialog.component';
import { BulkDialogComponent } from './dialogs/bulk-dialog/bulk-dialog.component';

registerPlugin(
    FilePondPluginFileValidateType, 
    FilePondPluginImagePreview, 
    FilePondPluginImageEdit,
    FilePondPluginImageExifOrientation,
    FilePondPluginImageCrop,
    FilePondPluginImageResize,
    FilePondPluginImageTransform
);

@NgModule({
    declarations: [
        DashboardComponent,
        IndustryComponent,
        AddDialogComponent,
        EditDialogComponent,
        ServiceComponent,
        SingleDialogComponent,
        TypeComponent,
        SmallDialogComponent,
        AllListDialogComponent,
        UpdateDialogComponent,
        BulkDialogComponent,
    ],
    imports: [
        CommonModule,
        DashboardRoutingModule,
        SingleSharedModule,
        HttpClientModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgMaterialModule,
        MatSelectCountryModule.forRoot('en'),
        BrowserModule,
        FilePondModule,
        PeopleModule,
        BrandsModule,
        ServicesModule,
        CompaniesModule,
        ClientsModule,
        LoadingModule
    ],
    exports: [
        HttpClientModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgMaterialModule,
        MatSelectCountryModule,
        PeopleModule,
        BrandsModule,
        ServicesModule,
        CompaniesModule,
        SingleSharedModule
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
})
export class DashboardModule { }
