import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Service } from 'src/app/interfaces/service';
import { ServiceService } from 'src/app/services/service.service';
import { NotifierService } from 'angular-notifier';
import { OpendialogService } from 'src/app/services/opendialog.service';
import { ServicesText, NotificationText } from '../../lang/globals-en';
import { AddDialogComponent } from '../dialogs/add-dialog/add-dialog.component';
import { SingleDialogComponent } from '../dialogs/single-dialog/single-dialog.component';
import { SmallDialogComponent } from '../dialogs/small-dialog/small-dialog.component';
import { SeoService } from 'src/app/services/seo.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-services',
    templateUrl: './services.component.html',
    styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
    services: Service[];
    modalFormGroup: FormGroup;
    name = new FormControl();
    formSaved = false;
    serviceSlug;
    notifications = NotificationText;
    servicesNotification = ServicesText;
    error: null;
    errorStatus: null;
    private subscribtion: Subscription;
    private subscribtionDelete: Subscription;
    private deleteServiceSubscription: Subscription;
    private submitServiceSubscription: Subscription;

    constructor(
        private serviceService: ServiceService,
        private formBuilder: FormBuilder,
        private notifier: NotifierService,
        private openDialogService: OpendialogService,
        private seoService: SeoService) { 

            this.seoService.setSeoMetaTags({title: 'Services'});
            
            this.submitServiceSubscription = this.openDialogService.modalData.subscribe(res => {
                if (res.type == 'service') {
                    this.submit(res.data);
                }
            })

            this.deleteServiceSubscription = this.openDialogService.deleteService.subscribe((res: any) => {
                this.delete(res.slug)
            })
        }

    ngOnInit(): void {
        this.getServices();    
    }

    ngOnDestroy(): void {
        if (this.subscribtion) {
            this.subscribtion.unsubscribe();
        }

        if (this.subscribtionDelete) {
            this.subscribtionDelete.unsubscribe();
        }

        if (this.deleteServiceSubscription) {
            this.deleteServiceSubscription.unsubscribe();
        }

        if (this.submitServiceSubscription) {
            this.submitServiceSubscription.unsubscribe();
        }
    }

    getServices() {
        this.serviceService.index().subscribe((res: any) => {
            this.services = res.data
        })
    }

    submit(data): void {
        this.subscribtion = this.serviceService.store(data).subscribe(
            data => this.handleResponse(data),
            error => this.handleError(error, data),
        );
    }

    handleResponse(data) {
        this.serviceService.refreshJsonServicesCache();
        this.notifier.notify('success', this.servicesNotification.added);
        this.getServices();
        this.openDialogService.closeModal.next(true);
        this.openDialogService.closeModal.next(false);
        this.subscribtion.unsubscribe();
    }

    handleError(error, data) {
        this.serviceService.deleteError(data).subscribe((res: any) =>{
            console.log(res);
        });
        this.notifier.notify('error', this.servicesNotification.add_err);
        this.openDialogService.closeModal.next(true);
        this.openDialogService.closeModal.next(false);
    }

    // updateSubmit() {
    //     this.serviceService.update(this.modalFormGroup.getRawValue(), this.serviceSlug).subscribe((res: any) => {
    //         this.getServices();
    //     })
    // }

    openDialog() {
        this.openDialogService.openModal('service', SingleDialogComponent);
    }

    openDelete(slug) {
        this.openDialogService.openDeleteDialog('service', SmallDialogComponent, slug);
    }

    delete(slug){
        this.subscribtionDelete = this.serviceService.delete(slug).subscribe(
            data => this.handleDeleteResponse(data),
            error => this.handleDeleteError(error),
        )
    }

    handleDeleteResponse(data) {
        this.subscribtionDelete.unsubscribe();
        this.serviceService.refreshRelationsCache();
        this.serviceService.refreshJsonServicesCache();
        this.serviceService.refreshJsonServicesCache();
        this.notifier.notify('success', this.servicesNotification.removed);
        this.getServices();
    }

    handleDeleteError(error) {
        this.notifier.notify('error', this.servicesNotification.remove_err);
        this.error = error.error.message;
        this.errorStatus = error.status;
    }

}
