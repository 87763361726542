import { Component, OnInit, EventEmitter, Output, Input, HostListener } from '@angular/core';
import { CustomDialogService } from 'src/app/services/custom-dialog.service';
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import { SidenavService } from 'src/app/services/sidenav.service';
import { ImageUrl } from 'src/app/components/lang/globals-en';

@Component({
  selector: 'app-all-custom-dialog',
  templateUrl: './all-custom-dialog.component.html',
  styleUrls: ['./all-custom-dialog.component.scss']
})

export class AllCustomDialogComponent implements OnInit {
  allData = [];
  opened;
  imageUrl = ImageUrl;
  imageUrlType;
  updateModal: boolean = false;
  tippyContent: NgxTippyProps = {
    allowHTML: true,
    interactive: true,
  };
  typePosition;
  itemClicked: any;
  typeGroup;

//   typeGroup = {
//     group: 'people',
//     // type: {
//     //   name:"Person",
//     //   plural:"people"
//     // }
//     type: null
// };
  

  constructor(
    private customDialog: CustomDialogService,
    private sidenavService: SidenavService) {
    this.customDialog.customModalData.subscribe((res: any)=> {
      this.allData = res.data;
      this.typePosition = res.type.group;
      this.typeGroup = res.type;

      if(this.typePosition == 'brands'){
          this.imageUrlType = this.imageUrl.brands;
          ;
      }else if(this.typePosition == 'people') {
        this.imageUrlType = this.imageUrl.people;
        ;
      }else if(this.typePosition == 'person-key-contact'){
        this.imageUrlType = this.imageUrl.people;
        ;
        const keyPersonDatas: any = [];
        this.allData.forEach((element, index) => {
            keyPersonDatas[index] = element.person
        });
        this.allData = keyPersonDatas;
      }else{
        this.imageUrlType = this.imageUrl.company;
      }
    });
    this.customDialog.cast.subscribe(data => this.opened = data);

    this.customDialog.customModalEditMode.subscribe((res: any) => {
      this.updateModal = res;      
    });
  }

  ngOnInit(): void {
  }

  closePopup() {
    this.customDialog.close();
    this.allData = [];
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.handleKeyUp();
  }

  private handleKeyUp() {
    if (!this.updateModal) {
      this.closePopup();
    }
  }

  openRightSidenav(item, event) {
    event.stopPropagation();
    this.itemClicked = item;
    this.sidenavService.open();
    this.sidenavService.sidenavData.next({data: item, type: this.typeGroup});
  }

}