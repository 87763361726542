import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SidenavService {
    public sidenavData: Subject<any> = new Subject();
    public sidenavOpened = new BehaviorSubject<boolean>(false);
    cast = this.sidenavOpened.asObservable();

    toogleOpen(){
        this.sidenavOpened.next(!this.sidenavOpened);
    }
    
    open(){
        this.sidenavOpened.next(true);
    }

    close(){
        this.sidenavOpened.next(false);
    }
}
