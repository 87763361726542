import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { NotifierService } from 'angular-notifier';
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ImageUrl, KeyContactsText, NotificationText, PeopleText } from 'src/app/components/lang/globals-en';
import { KeyContactsService } from 'src/app/services/key-contacts.service';
import { OpendialogService } from 'src/app/services/opendialog.service';
import { PersonService } from 'src/app/services/person.service';
import { SidenavService } from 'src/app/services/sidenav.service';
import { AddDialogComponent } from '../../dialogs/add-dialog/add-dialog.component';
import { AllListDialogComponent } from '../../dialogs/all-list-dialog/all-list-dialog.component';
import { PositionService } from 'src/app/services/position.service';
import { GeneralService } from 'src/app/services/general.service';
import { BrandService } from 'src/app/services/brand.service';
import { Company } from 'src/app/interfaces/company';
import { CompanyService } from 'src/app/services/company.service';
import { CustomDialogService } from 'src/app/services/custom-dialog.service';

@Component({
    selector: 'app-ss-key-contacts',
    templateUrl: './ss-key-contacts.component.html',
    styleUrls: ['./ss-key-contacts.component.scss']
})
export class SsKeyContactsComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() typeData;
    @Input() type;
    @Input() pluralType;

    key_contacts: any[] = [];
    people_key_contacts: any[] = [];
    getKeyContactsData;
    text = KeyContactsText;
    typePosition;
    notifications = NotificationText;
    personNotifications = PeopleText;
    formControl = new FormControl();
    storeData = {
        person: null,
        slug: null,
        brand_id: null,
        company_id: null,
        position: null,
        exists: false,
    };
    options;
    filteredOptions: Observable<any>;
    openInput:boolean = false;
    private subscribtion:any;
    private storeSubscribtion:any;
    private updateSubscribtion:any;
    private updateModalSubscribtion:any;
    imageUrl = ImageUrl.peopleIcon;
    typeGroup = {
        group: 'person-key-contact',
        type: {
            name: 'Key Contact',
            plural: "Key Contacts"
        }
    };
    dataCount;
    tippyContent: NgxTippyProps = {
        allowHTML: true,
        interactive: true,
    };
    selectedListItem: any;
    isKeyContactClicked:boolean;
    keyContactClicked: any;
    loadedContent:boolean = false;
    allPosition;
    isEmailGmail;
    allDataModal;
    showAllListCustomIsOpen = false;
    @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
    @ViewChild("inputField") inputField: ElementRef;

    constructor(
        private keyContactService: KeyContactsService,
        private personService: PersonService,
        private notifier: NotifierService,
        private openDialogService: OpendialogService,
        private sidenavService: SidenavService,
        private positionService: PositionService,
        private brandService: BrandService,
        private companyService: CompanyService,
        private generalService: GeneralService,
        public customDialog: CustomDialogService) {
            this.subscribtion = this.openDialogService.modalData.subscribe((res:any) => {
                if (res.type == 'person-key-contact') {
                    this.submit(res.data);
                }
            })

            this.keyContactService.refreshKeyContacts.subscribe(res => {
                this.getKeyContacts();
            })

            this.customDialog.customModalisOpen.subscribe((res: any)=> {
                this.showAllListCustomIsOpen = res;
            });

            this.updateModalSubscribtion = this.openDialogService.updateModalData.subscribe((res: any) => {
                if(res.type.group == "person-key-contact"){
                    this.updatePerson(res.slug, res.data);

                    if(this.showAllListCustomIsOpen){
                        this.showAllListCustom();
                    }
                }
            })
        }
        
    
    @HostListener('window:keyup.esc') onKeyUp() {
        this.resetInputForm();
    }

    ngOnInit(): void {
        if(this.type == 'brand'){
            this.storeData.brand_id = this.typeData.id;
        } else {
            this.storeData.company_id = this.typeData.id
        }
        this.sidenavService.cast.subscribe(data => this.isKeyContactClicked = data);
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.getKeyContacts();
        }, 0);
    }

    listItems(){
        this.personService.getList().subscribe((res:any) => {
            if(this.selectedListItem){
                this.options = res.data.filter(ar => !this.selectedListItem.find(rm => (rm.person.slug === ar.slug) ));
            }else{
                this.options = res.data;
            }
            this.filterOptions();
        })
    }

    ngOnDestroy(): void {
        if(this.subscribtion){
            this.subscribtion.unsubscribe();
        }
        if (this.storeSubscribtion) {
            this.storeSubscribtion.unsubscribe();
        }
        if (this.updateSubscribtion) {
            this.updateSubscribtion.unsubscribe();
        }
        if (this.updateModalSubscribtion) {
            this.updateModalSubscribtion.unsubscribe();
        }
    }
    

    getKeyContacts(){
        this.keyContactService.getContactsByTypeLimitData(this.type, this.typeData.id).subscribe((res:any) => {
            this.dataCount = res.allDataCount;
            this.key_contacts = res.data;
            
            setTimeout(() => {
                this.loadedContent = true;
            }, 100);
        })
    }

    filterOptions(){
        setTimeout(() => {
            this.filteredOptions = this.formControl.valueChanges.pipe(
                startWith(''),
                map(value => (typeof value === 'string' ? value : value?.name)),
                map(name => (name ? this._filter(name) : this.options.slice())),
            );
        }, 0);
    }

    displayFn(keyContact): string {
        return keyContact && keyContact.name ? keyContact.name : '';
    }
    
    private _filter(name: string) {
        const filterValue = name.toLowerCase();
        return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    onInputEnter(e){
        this.positionService.index().subscribe((res: any) => {
            this.allPosition = res.data;
        })
        if(this.formControl.value){
            this.storeData.person = this.formControl.value.id ? this.formControl.value.id : this.formControl.value;
            if(this.formControl.value.slug){
                this.submit();
            } else {
                this.openDialogService.openModal(this.typeGroup.group, AddDialogComponent, this.storeData, this.allPosition);
                this.resetInputForm()
            }
        }
    }

    submit(data=null){
        let submitData;
        if(data){
            submitData = data;
        } else {
            this.storeData.person = this.formControl.value.id ? this.formControl.value.id : this.formControl.value;
            if(this.formControl.value.name){
                this.storeData.exists = true;
                this.storeData.slug = this.formControl.value.slug;
            } else {
                this.storeData.exists = false;
                this.storeData.slug = null;
            }
            submitData = this.storeData
        }
        
        if(submitData){
            this.storeSubscribtion = this.keyContactService.store(submitData).subscribe(
                data => this.handleResponse(data),
                error => this.handleError(error, submitData),
            );
            this.keyContactService.refreshKeyContactsCache(this.type, this.typeData.id)
        } else {
            setTimeout(() => {
                this.inputField.nativeElement.focus();
            }, 200);
        }
    }

    updatePerson(id, data){
        this.keyContactService.refreshKeyContactsCache(this.type, this.typeData.id)
        data.isKeyContact = true;
        if(this.type == 'brand'){
            data.brand = this.typeData.id;
        } else {
            data.company = this.typeData.id
        }
        this.updateSubscribtion = this.personService.update(id, data).subscribe(
            data => this.handleUpdateResponse(data),
            error => this.handleUpdateError(error),
        );
    }

    showAllList() {
        this.keyContactService.getContactsByTypeAllData(this.type, this.typeData.id).subscribe((res:any) => {
            this.openDialogService.openAllListModal(this.typeGroup, AllListDialogComponent, res.data);
        });
    }

    showAllListCustom(){
        this.keyContactService.getContactsByTypeAllData(this.type, this.typeData.id).subscribe((res:any) => {
            this.allDataModal = res.data;
            if(res.data){
                setTimeout(() => {
                    this.customDialog.open();
                }, 200);
            }
            this.customDialog.customModalData.next({data:  res.data, type: this.typeGroup});
        });
    }
    

    toggleRightSidenav(keyContact, event) {
        event.stopPropagation();
        this.keyContactClicked = keyContact.person;
        const emailData = {
            email: keyContact.person.email
        }
        if(keyContact.person.email){
            this.generalService.checkEmailDns(emailData).subscribe((res: any) => {
                this.isEmailGmail = res;
                this.sidenavService.sidenavData.next({data: this.keyContactClicked, type: this.typeGroup, isGmail: this.isEmailGmail});
                this.sidenavService.open();
            })
        }else{
            this.isEmailGmail = false;
            this.sidenavService.sidenavData.next({data: this.keyContactClicked, type: this.typeGroup, isGmail: this.isEmailGmail});
            this.sidenavService.open();
        }
    }

    handleResponse(data) {
        this.personService.refreshRelPeopleCache(true);
        this.notifier.notify('success', this.notifications.relationship);
        this.resetInputForm();
        this.getKeyContacts();
        this.listItems();
        this.personService.reloadPeople.next();
        this.storeSubscribtion.unsubscribe();

        if(this.type == 'brand'){
            this.brandService.refreshRelBrandsCache();
        }
        if(this.type == 'company'){
            const typesOfCompanies = this.typeData.types[0].slug;
            this.companyService.refreshRelCompaniesCache(typesOfCompanies);
        }
    }

    handleError(error, data) {
        this.keyContactService.deleteError(data).subscribe();
        this.notifier.notify('error', this.notifications.relationship_err);
        this.storeSubscribtion.unsubscribe();
    }

    handleUpdateResponse(data) {
        this.personService.refreshRelPeopleCache(true);
        this.notifier.notify('success', this.personNotifications.updated);
        this.getKeyContacts();
        this.listItems();
        this.personService.refreshPersonCache(data.data.slug);
        this.personService.reloadPeople.next();
        this.updateSubscribtion.unsubscribe();
    }

    handleUpdateError(error) {
        this.notifier.notify('error', this.personNotifications.update_err);
        this.updateSubscribtion.unsubscribe();
    }

    add(){
        if(this.key_contacts?.length === 10){
            this.keyContactService.getContactsByTypeAllData(this.type, this.typeData.id).subscribe((res:any) => {
                setTimeout(() => {
                    this.inputField?.nativeElement.focus()
                }, 200);
                this.selectedListItem = res.data;
            this.openInput = true;
                this.listItems();
            });
        } else {
            setTimeout(() => {
                this.inputField?.nativeElement.focus()
            }, 200);
            this.selectedListItem = this.key_contacts
            this.openInput = true;
            this.listItems();
        }
    }

    resetInputForm(){
        this.openInput = false;
        this.storeData.person = '';
        this.autocomplete.closePanel();
        this.formControl.setValue(null);
    }

    deleteRelationship(slug, event){
        event.stopPropagation();
        this.personService.refreshRelPeopleCache(false);
        if(this.type == 'brand'){
            this.brandService.refreshRelBrandsCache();
        }
        if(this.type == 'company'){
            const typesOfCompanies = this.typeData.types[0].slug;
            this.companyService.refreshRelCompaniesCache(typesOfCompanies);
        }

        this.keyContactService.removeRelationship(slug, this.type, this.typeData.id).subscribe(
            data => this.handleDeleteRelationshipResponse(data),
            error => this.handleDeleteRelationshipError(error),
        )
    }

    handleDeleteRelationshipResponse(data){
        this.notifier.notify('success', this.notifications.relationshipDel);
        this.getKeyContacts();
        this.listItems();
    }
    
    handleDeleteRelationshipError(error){
        this.notifier.notify('error', this.notifications.relationshipDel_err);
    }

    closePopup() {
        this.customDialog.close();
    }
}
