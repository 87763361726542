    <div style="padding-top: 100px" class="container-fluid">
        
        <div class="button-wrappers">
            <button (click)="openDialog()" class="new-services">Add new Services</button>
        </div>

        <div class="card">
            <div class="card-header">
                Services List
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class=" table table-bordered table-striped table-hover datatable client-table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr data-entry-id="" *ngFor="let service of services">
                                <td>{{ service.id }}</td>                               
                                <td>{{ service.name }}</td>
                                <td>
                                    <a class="btn btn-xs btn-primary mr-2"  routerLink="/services/{{ service.slug }}">
                                        View
                                    </a>
                                    <a class="btn btn-xs btn-danger" (click)="openDelete(service.slug)">
                                        Delete
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>