import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LoaderService } from './loader.service';
import { finalize } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

    constructor(public loaderService: LoaderService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.loaderService.isLoading.next(true);
        let interval;
        const t0 = performance.now();

        interval = setInterval(() => {
            const t1 = performance.now();
            const responseTime = (t1 - t0) / 1000;
            let percentage = this.rndInc(responseTime);
            this.loaderService.loadingPercent.next(percentage);
        }, 50);

        return next.handle(req).pipe(
            finalize(() => {
                clearInterval(interval);
                this.loaderService.loadingPercent.next(100);
                setTimeout(() => {
                    this.loaderService.isLoading.next(false);
                    this.loaderService.resetLoader.next(true);
                }, 200);
            })
        );
    }

    private rndInc(reqTime) {
        let rnd;
        if (reqTime >= 0 && reqTime < 0.50) {
            rnd = (Math.random() * (5 - 3 + 1) + 3) / 100;
        } else if (reqTime >= 0.50 && reqTime < 0.75) {
            rnd = (Math.random() * 3) / 100;
        } else if (reqTime >= 0.75 && reqTime < 1) {
            rnd = (Math.random() * 2) / 100;
        } else if (reqTime >= 1 && reqTime < 1.5) {
            rnd = 0.005;
        } else if (reqTime >= 1.5 && reqTime < 3) {
            rnd = 0.001;
        } else {
            rnd = 0;
        }
        return rnd*100;
    }
}
