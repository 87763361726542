<header *ngIf="loggedIn" class="app-header navbar"  [ngClass]="{sideMenuClicked: sideMenuClicked}">
    <button class="navbar-toggler sidebar-toggler d-lg-none mr-auto" type="button" data-toggle="sidebar-show" >
        <span class="navbar-toggler-icon"></span>
    </button>

    <!-- <button class="navbar-toggler sidebar-toggler d-md-down-none" type="button" data-toggle="sidebar-lg-show" (click)="onClickSideBarMenu()">
        <span class="navbar-toggler-icon"></span>
    </button> -->
</header>
<div *ngIf="loggedIn" class="sidebar" [ngClass]="{sideMenuClicked: sideMenuClicked}">
    <nav class="sidebar-nav">
        <a class="navbar-brand" routerLink="/">
            <img src="../../../../assets/img/pulladigital.png" alt="">
        </a>
        <ul class="nav f-menu-link">
            <li class="nav-item">
                <a routerLink="/dashboard" routerLinkActive="active" class="nav-link">
                    Dashboard
                </a>
            </li>

            <li class="nav-item nav-dropdown" [ngClass]="{'open': openDashboardMenu}" (click)="openDashboard()">
                <a class="nav-link nav-dropdown-toggle">
                    Companies
                </a>
                <ul class="nav-dropdown-items">
                    <li class="nav-item">
                        <a routerLink="/companies" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="nav-link">
                            All
                        </a>
                    </li>
                    <ng-container *ngIf="companyTypes">
                        <li class="nav-item" *ngFor="let type of companyTypes" style="position: relative;" (mouseover)="onMouseOver(type.name)"
                        (mouseout)="onMouseOut()">
                            <a routerLink="/companies/type/{{ type.slug }}" routerLinkActive="active" class="nav-link" >
                                {{type.plural}}
                            </a>
                            <div class="icons" [ngClass]="{'activeType': activeItem === type.name}">
                                <span class="edit"
                                    (click)="openUpdate($event, type)">
                                    <img src="./../../../../../assets/img/edit.png" alt="edit">
                                </span>
                                <span class="delete"
                                    (click)="openDelete($event, type.slug)">
                                    <img src="./../../../../../assets/img/close_big.png" alt="delete">
                                </span>
                            </div>
                        </li>
                    </ng-container>
                </ul>
            </li>
            <li class="nav-item">
                <a routerLink="/people" routerLinkActive="active" class="nav-link" >
                    People
                </a>
            </li>
            <li class="nav-item">
                <a routerLink="/brands" routerLinkActive="active" class="nav-link">
                    Brands
                </a>
            </li>
            <li class="nav-item">
                <a routerLink="/clients" routerLinkActive="active" class="nav-link">
                    Clients
                </a>
            </li>
            <li class="nav-item">
                <a routerLink="/services" routerLinkActive="active" class="nav-link">
                    Services
                </a>
            </li>
            <li class="nav-item">
                <a routerLink="/industries" routerLinkActive="active" class="nav-link">
                    Industries
                </a>
            </li>
            <li class="nav-item">
                <button (click)="logout()" class="nav-link">
                    Logout
                </button>
            </li>
        </ul>
    </nav>
    
    <!-- <button class="sidebar-minimizer brand-minimizer" (click)="onClickSideBarMenuMin()" type="button"></button> -->
</div>
