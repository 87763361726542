import { Component, Input, ViewChild, HostListener, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from 'src/app/services/sidenav.service';
import { ImageUrl } from 'src/app/components/lang/globals-en';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
    @ViewChild('sidenav', {static: true}) public sidenav: MatSidenav;
    @Input() dataNav;
    @Input() opened;
    @Input() typePosition;
    imageUrl = ImageUrl;
    imageUrlType;
    isGmail;
    urlSlug;

    constructor(
        private sidenavService: SidenavService) { 
    }
    
    ngOnInit(): void {
        this.sidenavService.sidenavData.subscribe((res: any)=> {
            this.dataNav = res.data;
            this.typePosition = res.type;
            this.isGmail = res.isGmail;
            if(this.typePosition.group == 'brands'){
                this.imageUrlType = this.imageUrl.brands;
                this.urlSlug = 'brands'
            }else if(this.typePosition.group == 'people' || this.typePosition.group == 'person-key-contact') {
                this.imageUrlType = this.imageUrl.people;
                this.urlSlug = 'people';
            }else{
                this.imageUrlType = this.imageUrl.company;
            }
        });
        this.sidenavService.cast.subscribe(data => this.opened = data);
        this.sidenavService.close();
    }

    closeSideNav(){
        this.sidenavService.close();
    }

    @HostListener('window:keyup.esc') onKeyUp() {
        this.sidenavService.close();
    }
}
