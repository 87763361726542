import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '../config';
import { Subject } from 'rxjs';
import { TokenService } from './auth/token.service';
import { CacheResolverService } from './cache-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class PositionService {
    config:Config;
    public reloadRelPositions: Subject<any> = new Subject();

    constructor(
        private http: HttpClient, 
        private token: TokenService,
        private cacheResolver: CacheResolverService) { }

    headers = new HttpHeaders({'Content-Type': 'application/json', 'Authorization':`Bearer ${this.token.get()}`});

    index(){
        return this.http.get(Config.url + 'positions', {headers:this.headers});
    }
    
    getList(){
        return this.http.get(Config.url + 'positions/getJson/list', {headers:this.headers});
    }

    getRelationships(type, slug){
        return this.http.get(Config.url + type + '/' + slug + '/relationships/positions', {headers:this.headers});
    }
    
    store(data){
        return this.http.post(Config.url + 'positions', data, {headers:this.headers});
    }
    
    deleteError(data){
        return this.http.post(Config.url + 'positions/data/error', data, {headers:this.headers}); 
    }
    
    deleteRelationship(slug, data){
        return this.http.post(Config.url + 'positions/'+ slug +'/deleteRelationship', data, {headers:this.headers});
    }

    refreshRelPositionsCache(type, slug){
        this.cacheResolver.delete(Config.url + type + '/' + slug + '/relationships/positions')
        this.refreshJsonPositionsCache();
    }

    refreshJsonPositionsCache(){
        this.cacheResolver.delete(Config.url + 'positions/getJson/list')
        this.cacheResolver.delete(Config.url + 'positions')
    }
    
    refreshAllRelPositionsCache(){
        const cacheItems = { ...localStorage };
        const keysArray = Object.keys(cacheItems);
        for (let i = 0; i < keysArray.length; i++) {
            if(keysArray[i].includes('/relationships/positions')){
                this.cacheResolver.delete(keysArray[i])
            }
        }
    }
}
