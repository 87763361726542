import { Component, OnInit } from '@angular/core';
import { SeoService } from 'src/app/services/seo.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    constructor(private seoService: SeoService) {
        this.seoService.setSeoMetaTags({ title: 'Dashboard' });
    }

    ngOnInit(): void {
    }

}
