import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BrandsComponent } from "./brands.component";

@NgModule({
    declarations: [BrandsComponent],
    imports: [
        CommonModule,
        RouterModule.forChild([
            {
                path: '',
                pathMatch: 'full',
                component: BrandsComponent
            }
        ])
    ]
})

export class BrandsModule{

}