<div class="add-dialog" >
    <div class="add-dialog_container">
        <div mat-dialog-content class="add-dialog-content">
            <div class="add-dialog-header">
                <button mat-dialog-close matDialogClose class="close">
                    <span aria-hidden="true">×</span>
                </button>
                <ng-container *ngIf="companyTypesData; else typeTitle">
                    <h3 mat-dialog-title>Add new {{ companyTypesData[0] | titlecase }}</h3>
                </ng-container>
                <ng-template #typeTitle>
                    <ng-container *ngIf="type == 'person-key-contact'; else othersType">
                        <h3 mat-dialog-title>Add new Key Contact</h3>
                    </ng-container>
                    <ng-template #othersType>
                        <h3 mat-dialog-title>Add new {{ type | titlecase }}</h3>
                    </ng-template>
                </ng-template>
            </div>
            <form [formGroup]="modalFormGroup" id="ngForm">
                <div class="body-content" cdkScrollable>
                    <div class="modal-profile">
                        <div class="modal-avatar--label">
                            <ng-container *ngIf="type == 'person' || type== 'person-key-contact'; else logoText">
                                <p>Avatar</p>
                            </ng-container>
                            <ng-template #logoText>
                                <p>Logo</p>
                            </ng-template>
                        </div>
                        <div class="modal-maxsize--info">
                            <p> 2MB</p>
                        </div>
                        <div class="logo" [ngClass]="(type == 'person' || type == 'person-key-contact') ? 'add-avatar-wrapper': 'add-logo-wrapper'">
                            <file-pond #myPond [options]="pondOptions"
                                (onaddfile)="pondHandleAddFile($event)">
                            </file-pond>
                        </div>
                    </div>
                    <div class="inputs-wrapper">
                        <div class="modal-info">
                            <!-- Name -->
                            <mat-form-field class="full-width-input" appearance="standard">
                                <mat-label class="label">Name</mat-label>
                                <input matInput type="text" autocomplete="new-password" class="input" formControlName="name"
                                    #nameInput required [autofocus]="focusInput">
                                <mat-error class="error" *ngIf="(modalFormGroup.get('name')).hasError('required')">Name is
                                    required</mat-error>
                            </mat-form-field>
                            <!-- Company Type -->
                            <mat-form-field class="example-chip-list" appearance="standard" *ngIf="type == 'company' && !companyTypesData">
                                <mat-label class="label">Type</mat-label>
                                <mat-chip-list #chipList aria-label="Company type selection" formControlName="companyTypes" required>
                                    <mat-chip *ngFor="let companyType of companyTypes"
                                        (removed)="removeCompanyType(companyType)">
                                        {{companyType}}
                                        <button matChipRemove>
                                            <mat-icon>cancel</mat-icon>
                                        </button>
                                    </mat-chip>
                                    <input placeholder="New Company Type..." #companyTypeInput matInput formControlName="companyTypes"
                                        [formControl]="companyType" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" 
                                        class="input" required>
                                </mat-chip-list>
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedCompanyType($event)">
                                    <mat-option *ngFor="let companyTypes of filteredCompanyTypes | async"
                                        [value]="companyTypes.id">
                                        {{companyTypes.name}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error class="error" *ngIf="(modalFormGroup.get('companyTypes')).hasError('required')">Type is
                                    required</mat-error>
                            </mat-form-field>
                            <!-- Position -->
                            <mat-form-field class="example-chip-list" appearance="standard" *ngIf="type == 'person' || type == 'person-key-contact'">
                                <mat-label class="label">Position</mat-label>
                                <mat-chip-list #chipList aria-label="Position select">
                                    <mat-chip *ngFor="let position of positions" class="positionItem" (removed)="removePosition(position)">
                                        {{position}}
                                        <button matChipRemove>
                                            <mat-icon>cancel</mat-icon>
                                        </button>
                                    </mat-chip>
                                    <input placeholder="New Position..." #positionInput matInput [formControl]="position"
                                        [matAutocomplete]="auto" [matChipInputFor]="chipList"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        (matChipInputTokenEnd)="addPosition($event)" [matChipInputAddOnBlur]="addOnBlur" class="input">
                                </mat-chip-list>
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedPosition($event)">
                                    <mat-option *ngFor="let positions of filteredPositions | async" [value]="positions.id">
                                        {{positions.name}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <!-- Industries -->
                            <mat-form-field class="example-chip-list" appearance="standard" *ngIf="type == 'brand'">
                                <mat-label class="label">Industry</mat-label>
                                <mat-chip-list #chipList aria-label="Industry select">
                                    <mat-chip *ngFor="let industry of industries" class="industryItem" (removed)="removeIndustry(industry)">
                                        {{industry}}
                                        <button matChipRemove>
                                            <mat-icon>cancel</mat-icon>
                                        </button>
                                    </mat-chip>
                                    <input placeholder="New Industry..." #industryInput matInput [formControl]="industry"
                                        [matAutocomplete]="auto" [matChipInputFor]="chipList"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        (matChipInputTokenEnd)="addIndustry($event)" [matChipInputAddOnBlur]="addOnBlur" class="input">
                                </mat-chip-list>
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedIndustry($event)">
                                    <mat-option *ngFor="let industries of filteredIndustries | async" [value]="industries.id">
                                        {{industries.name}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <!-- Email -->
                            <mat-form-field class="full-width-input" appearance="standard">
                                <mat-label class="label">Email</mat-label>
                                <input matInput type="text" autocomplete="new-password" class="input"
                                    formControlName="email" #emailInput>
                                <mat-error
                                    *ngIf="(modalFormGroup.get('email')).hasError('email') && !(modalFormGroup.get('email')).hasError('required')">
                                    Please write a valid email</mat-error>
                            </mat-form-field>
                            <!-- Description -->
                            <mat-form-field class="full-width-input" appearance="standard">
                                <mat-label class="label">Description</mat-label>
                                <textarea matInput class="input" rows="7" formControlName="description"></textarea>
                            </mat-form-field>
                            <!-- Website -->
                            <mat-form-field class="full-width-input" appearance="standard">
                                <mat-label class="label">Website</mat-label>
                                <input matInput type="text" autocomplete="new-password" class="input"
                                    formControlName="website" #websiteInput>
                                <mat-error *ngIf="(modalFormGroup.get('website')).hasError('pattern') ">Please enter a valid
                                    website</mat-error>
                            </mat-form-field>
                            <!-- Phone -->
                            <mat-form-field class="full-width-input" appearance="standard">
                                <mat-label class="label">Phone</mat-label>
                                <input matInput type="text" autocomplete="new-password" class="input"
                                    formControlName="phone" #phoneInput>
                            </mat-form-field>
                            <!-- Address -->
                            <mat-form-field class="full-width-input" appearance="standard">
                                <mat-label class="label">Address</mat-label>
                                <input matInput type="text" autocomplete="new-password" class="input"
                                    formControlName="address" #addressInput>
                            </mat-form-field>
                            <!-- Country -->
                            <mat-select-country appearance="standard" label="Country" class="select_country"
                                [countries]="country" formControlName="country_id" autocomplete="off">
                            </mat-select-country>
                        </div>
                        <!-- Socials -->
                        <div class="social-wrapper">
                            <h3>Social networks</h3>
                            <div>
                                <mat-form-field class="full-width-input" appearance="standard">
                                    <mat-label class="label">Behance</mat-label>
                                    <input matInput type="text" class="input" formControlName="behance" #behanceInput>
                                </mat-form-field>
                                <mat-form-field class="full-width-input" appearance="standard">
                                    <mat-label class="label">Facebook</mat-label>
                                    <input matInput type="text" class="input" formControlName="facebook" #facebookInput>
                                </mat-form-field>
                                <mat-form-field class="full-width-input" appearance="standard">
                                    <mat-label class="label">LinkedIn</mat-label>
                                    <input matInput type="text" class="input" formControlName="linkedin" #linkedinInput>
                                </mat-form-field>
                                <mat-form-field class="full-width-input" appearance="standard">
                                    <mat-label class="label">Instagram</mat-label>
                                    <input matInput type="text" class="input" formControlName="instagram" #instagramInput>
                                </mat-form-field>
                                <mat-form-field class="full-width-input" appearance="standard">
                                    <mat-label class="label">Twitter</mat-label>
                                    <input matInput type="text" class="input" formControlName="twitter" #twitterInput>
                                </mat-form-field>
                                <mat-form-field class="full-width-input" appearance="standard">
                                    <mat-label class="label">Vimeo</mat-label>
                                    <input matInput type="text" class="input" formControlName="vimeo" #vimeoInput>
                                </mat-form-field>
                                <mat-form-field class="full-width-input" appearance="standard">
                                    <mat-label class="label">Dribbble</mat-label>
                                    <input matInput type="text" class="input" formControlName="dribbble" #dribbbleInput>
                                </mat-form-field>
                                <mat-form-field class="full-width-input" appearance="standard">
                                    <mat-label class="label">Pinterest</mat-label>
                                    <input matInput type="text" class="input" formControlName="pinterest" #pinterestInput>
                                </mat-form-field>
                                <mat-form-field class="full-width-input" appearance="standard">
                                    <mat-label class="label">YouTube</mat-label>
                                    <input matInput type="text" class="input" formControlName="youtube" #youtubeInput>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="mat-dialog-actions">
        <button mat-raised-button class="f-sub-header-3" color="primary" type="submit" type="submit" [disabled]="!modalFormGroup.valid" (click)="submit()">Save</button>
    </div>
</div>
