import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})

export class UploadService {
    private cloudinaryBaseUrl = 'https://api.cloudinary.com/v1_1/dkkfi0ryp';
    private apiKey = '969332735454396';
    private apiSecret = 'xP6h2aYdYSTgGwViDTgBECm5ZXk';

    constructor(private http: HttpClient) { }

    uploadImage(file, type): Observable<any> {
        const file_data = file;
        const data = new FormData();
        let typePreset;
        if (type == 'person' || type == 'people' || type == 'person-key-contact') {
            typePreset = 'angular_cloudinary_people';
        } else if (type == 'brand' || type == 'brands') {
            typePreset = 'angular_cloudinary_brands';
        } else {
            typePreset = 'angular_cloudinary_companies';
        }

        data.append('file', file_data);
        data.append('upload_preset', typePreset);
        data.append('cloud_name', 'dkkfi0ryp');

        return this.http.post('https://api.cloudinary.com/v1_1/dkkfi0ryp/image/upload', data);
    }

    deleteImage(publicId): Observable<any> {
        const cloudName = 'dkkfi0ryp';
        const url = `https://api.cloudinary.com/v1_1/${cloudName}/image/destroy`;
        const formData = new FormData();
        const timestamp = Math.floor(Date.now() / 1000); 

        formData.append('public_id', publicId);
        formData.append('timestamp', timestamp.toString());
        formData.append('signature', this.generateSignature(publicId, timestamp, this.apiSecret));
        formData.append('api_key', this.apiKey);
        
        return this.http.post(url, formData);
    }

    generateSignature(publicKey: string, timestamp: number, privateKey: string): string {
        const message = `public_id=${publicKey}&timestamp=${timestamp}${privateKey}`;
        const signature = CryptoJS.SHA1(message).toString();
        return signature;
    }
}
