import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'src/app/config';
import { CacheResolverService } from '../cache-resolver.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
    config:Config;
    constructor(private http: HttpClient,
                private tokenService: TokenService,
                private cacheResolver: CacheResolverService) { }

    headers = new HttpHeaders({'Content-Type': 'application/json'});

    login( data ) {
        return this.http.post(Config.url + 'login', data, {headers:this.headers});
    }

    register( data ) {
        return this.http.post(Config.url + 'register', data, {headers:this.headers});
    }

    resetPassword( data ) {
        return this.http.post(Config.url + 'resetPassword', data, {headers:this.headers});
    }

    changePassword( data ) {
        return this.http.post(Config.url + 'changePassword', data, {headers:this.headers});
    }

    logout(){
        this.deleteSpecificRoutes()
        const token = this.tokenService.get();
        if(token){
            this.tokenService.remove();
            localStorage.clear();
        }
        return this.http.get(Config.url + 'logout');
    }

    // This function delete routes that are in condition
    deleteSpecificRoutes(){
        const cacheItems = { ...localStorage };
        const keysArray = Object.keys(cacheItems);
        for (let i = 0; i < keysArray.length; i++) {
            // if(keysArray[i].indexOf("/brands/") === -1 &&
            // keysArray[i].indexOf("/positions/") === -1 &&
            // keysArray[i].indexOf("/company-types/") === -1 &&
            // keysArray[i].indexOf("/services/") === -1 &&
            // keysArray[i].indexOf("/people/") === -1 &&
            // keysArray[i].indexOf("/clients/") === -1 &&
            // keysArray[i].indexOf("/companies/") === -1 &&
            // keysArray[i].indexOf("/industries/") === -1 ){
            // }
            this.cacheResolver.delete(keysArray[i])
        }
    }

}
