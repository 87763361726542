import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { Component, OnInit, Input, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NotificationText } from 'src/app/components/lang/globals-en';
import { GeneralService } from 'src/app/services/general.service';
import { PersonService } from 'src/app/services/person.service';
import { PositionService } from 'src/app/services/position.service';

@Component({
    selector: 'app-ss-positions',
    templateUrl: './ss-positions.component.html',
    styleUrls: ['./ss-positions.component.scss']
})
export class SsPositionsComponent implements OnInit {

    @Input() typeData;
    @Input() type;
    notifications = NotificationText;
    openInput = false;
    separatorKeysCodes: number[] = [ENTER, COMMA, TAB];
    position = new FormControl();
    positions: any[] = [];
    positionValues: any[] = [];
    allPositions: any[];
    filteredPositions: Observable<any[]>;
    relationshipPositions: any[] = [];
    storeData = {
        positions: null,
        person_id: null
    };
    selectedListItem: any;
    loadedContent: boolean = false;

    @ViewChild('positionInput') positionInput: ElementRef<HTMLInputElement>;

    constructor(
        private positionService: PositionService,
        private notifier: NotifierService,
        private generalService: GeneralService,
        private personService: PersonService
    ) {

        this.positionService.reloadRelPositions.subscribe(res => {
            this.getRelationshipPositions()
        })
    }
    
    @HostListener('window:keyup.esc') onKeyUp() {
        this.resetInputForm();
    }

    ngOnInit(): void {
        this.getRelationshipPositions()
        this.storeData.person_id = this.typeData.id
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.getAllPositionsList()
        }, 0);
    }

    getRelationshipPositions(fromResponse = false){
        this.positionService.getRelationships(this.type, this.typeData.slug ).subscribe((res:any) => {
            this.relationshipPositions = res.data;
            this.selectedListItem = res.data;
            if(fromResponse){
                this.personService.reloadPersonPositions.next(res.data);
            }
            setTimeout(() => {
                this.loadedContent = true;
            }, 100);
        })
    }

    mapPositionsToSelect(){
        if(this.relationshipPositions){
            this.relationshipPositions.forEach(item => {
                this.positions.push(item.name)
                this.positionValues.push(item.id);
            });
        }
    }
    
    getAllPositionsList(){
        this.positionService.getList().subscribe((res:any) => {
            if(this.selectedListItem){
                this.allPositions = res.data = res.data.filter(ar => !this.selectedListItem.find(rm => (rm.slug === ar.slug)));
            }else{
                this.allPositions = res.data;
            }
            this.filterPositions();
        })
    }
    
    filterPositions() {
        this.filteredPositions = this.position.valueChanges.pipe(
            startWith(null),
            map((position: string | null) => (position ? this._filter(position) : this.allPositions.slice())),
        );
    }
    
    add(){
        this.openInput = true;
        setTimeout(() => {
            this.positionInput?.nativeElement.focus()
        }, 0);
        this.mapPositionsToSelect();
    }
    
    submit(){
        var errorPositions = [];
        this.storeData.positions = this.positions;
        this.positionValues.forEach(element => {
            if (!this.generalService.isNumber(element) && element){
                errorPositions.push(element);
            }
        });
        this.positionService.store(this.storeData).subscribe(
            data => this.handleResponse(data),
            error => this.handleError(error, errorPositions),
        );
    }

    handleResponse(data) {
        this.positionService.refreshRelPositionsCache(this.type, this.typeData.slug);
        this.personService.refreshPersonCache(this.typeData.slug);
        this.notifier.notify('success', this.notifications.relationship);
        this.getRelationshipPositions(true);
        this.getAllPositionsList();
        this.resetInputForm();
        this.loadedContent = false;
    }

    handleError(error, errorPositions) {
        const dataErrorPositions = {
            positions: errorPositions
        }
        this.positionService.deleteError(dataErrorPositions).subscribe();
        this.notifier.notify('error', this.notifications.relationship_err);
    }
    
    addPosition(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (value) {
            if(!this.positions.includes(value)) {
                this.positions.push(value);
                this.positionValues.push(value);
            }
        }
        if (event.input) {
            event.input.value = '';
        }
        this.position.setValue(null);
    }
    
    removePosition(position: any): void {
        const index = this.positions.indexOf(position);
        const valueByName = this.positionValues.indexOf(position);
        let valueId;
        this.allPositions.forEach(item => {
            if(position == item.name){
                valueId = item.id
            }
        });
        if (index >= 0) {
            this.positions.splice(index, 1);
        }
        if (valueByName >= 0) {
            this.positionValues.splice(valueByName, 1);
        }
        if (valueId) {
            const arrayIndex = this.positionValues.indexOf(valueId);
            this.positionValues.splice(arrayIndex, 1);
        }
    }
    
    selected(event: MatAutocompleteSelectedEvent): void {
        if(!this.positions.includes(event.option.viewValue)) {
            this.positions.push(event.option.viewValue);
            this.positionValues.push(event.option.value);
        }
        this.positionInput.nativeElement.value = '';
        this.position.setValue(null);
        this.position.disable();
        this.position.enable();
    }
    
    private _filter(value: string): string[] {
        const filterValue = value.toString().toLowerCase();
        return this.allPositions.filter(position => position.name.toString().toLowerCase().includes(filterValue));
    }
    
    deleteRelationship(slug){
        let storeDelData = {
            custom_id: this.typeData.id,
            custom_name: 'person',
        };
        
        this.positionService.deleteRelationship(slug, storeDelData).subscribe(
            data => this.handleDeleteResponse(data),
            error => this.handleDeleteError(error),
        )
    }
    
    handleDeleteResponse(data) {
        this.positionService.refreshRelPositionsCache(this.type, this.typeData.slug);
        this.personService.refreshPersonCache(this.typeData.slug);
        this.notifier.notify('success', this.notifications.relationshipDel);
        this.getRelationshipPositions(true);
        this.getAllPositionsList();
    }

    handleDeleteError(error) {
        this.notifier.notify('error', this.notifications.relationshipDel_err);
    }

    resetInputForm(){
        this.openInput = false;
        this.positions = []
        this.positionValues = []
        this.storeData.positions = '';
        this.positionInput.nativeElement.value = '';
        this.filterPositions();
    }
}
