import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IndustryService } from 'src/app/services/industry.service';
import { Industry } from 'src/app/interfaces/industry';

@Component({
  selector: 'app-industry',
  templateUrl: './industry.component.html',
  styleUrls: ['./industry.component.scss']
})
export class IndustryComponent implements OnInit {
  slug;
  industry: Industry;

  constructor(
    private industryService: IndustryService,
    private route: ActivatedRoute ) { }

    

  ngOnInit(): void {
    this.slug = this.route.snapshot.params.slug;
    this.industryService.show(this.slug).subscribe((res: any) => {
        this.industry = res.data;     
      }
    );    
  }
}
