<div class="single-services single-section active">
    <div class="services-container single-container">
        <h2 class="f-sub-title-1">Services</h2>
        <div class="services-list--center list--center">

            <app-loading-module class="loader" [ngClass]="{'loaded': loadedContent}"></app-loading-module>

            <p class="f-body-4" [ngClass]="{'close': openInput, 'hidden': !loadedContent }">
                <ng-container *ngFor="let service of relationshipServices; let i = index">
                    <span class="serviceItem" title="{{ service.name }}">
                        <button id="services-button" (click)="deleteRelationship(service.slug)">
                            <img src="./../../../../../assets/img/off_close.png" alt="delete">
                        </button>
                        {{ service.name }}
                    </span>
                </ng-container>
                <em *ngIf="!relationshipServices" class="no-services f-body-2">No Services</em>

                <!-- <a href="#" class="f-button font-blue add add-agency-services add--services_button addHeightParent">+ Add</a> -->
                <button class="f-button font-blue add" [ngClass]="{'open': openInput}" (click)="add()">+Add</button>
            </p>

            <div class="services-input" [ngClass]="{'open': openInput}">
                <mat-form-field class="example-chip-list" [ngClass]="{'open': openInput}" appearance="standard">
                    <mat-label class="label">Service</mat-label>
                    <mat-chip-list #chipList aria-label="Service selection">
                        <mat-chip *ngFor="let service of services" (removed)="removeService(service)">
                            {{service}}
                            <button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip>
                        <input placeholder="New Service..." #serviceInput matInput [formControl]="service"
                            [matAutocomplete]="auto" [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="addService($event)" class="input">
                    </mat-chip-list>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                        <mat-option *ngFor="let service of filteredServices | async" [value]="service.id">
                            {{service.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
        <button class="f-button font-blue save" [ngClass]="{'open': openInput}" (click)="submit()">Save</button>
    </div>
</div>