import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CacheResolverService } from './cache-resolver.service';

const TIME_TO_LIVE = 20000;

@Injectable({
    providedIn: 'root'
})
export class CacheInterceptorService implements HttpInterceptor {
    constructor(private cacheResolver: CacheResolverService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(req.method !== 'GET'){
            if(req.method == 'POST'){
                this.cacheResolver.delete(req.url)
            }else if(req.method == 'DELETE'){
                const filterUrl =  req.url.substring(0, req.url.lastIndexOf('/'));
                this.cacheResolver.delete(filterUrl)
            }
            return next.handle(req);
        }
        const cachedResponse = this.cacheResolver.get(req.url);
        return cachedResponse ? of(cachedResponse) : this.sendRequest(req, next);
    }

    sendRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
        return next.handle(req).pipe(
            tap((event) => {
                if(event instanceof HttpResponse){
                    
                    // const modifiedHeaders = event.headers.set('Date', 'your_new_date_here');
                    // event = event.clone({ headers: modifiedHeaders });

                    this.cacheResolver.set(req.url, event, TIME_TO_LIVE);
                }
            })
        )
    }
}

function addHeaders(req: HttpRequest<any>): any {
    let headers = req.headers;
    headers = headers.set('tz-offset', getTimeZoneOffset());
    return { headers };
}

function getTimeZoneOffset(): string {
    const tzOffset = new Date().toTimeString().match(/([\+-]\d{4})/)[0];
    return [tzOffset.slice(0, 3), ':', tzOffset.slice(3)].join('');
}
