import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ActivatedRoute } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ImageUrl, NotificationText, PeopleText } from 'src/app/components/lang/globals-en';
import { OpendialogService } from 'src/app/services/opendialog.service';
import { PersonService } from 'src/app/services/person.service';
import { SidenavService } from 'src/app/services/sidenav.service';
import { AddDialogComponent } from '../../dialogs/add-dialog/add-dialog.component';
import { AllListDialogComponent } from '../../dialogs/all-list-dialog/all-list-dialog.component';
import { KeyContactsService } from 'src/app/services/key-contacts.service';
import { PositionService } from 'src/app/services/position.service';
import { BrandService } from 'src/app/services/brand.service';
import { GeneralService } from 'src/app/services/general.service';
import { CompanyService } from 'src/app/services/company.service';
import { BulkDialogComponent } from '../../dialogs/bulk-dialog/bulk-dialog.component';
import { CustomDialogService } from 'src/app/services/custom-dialog.service';
import { SkillService } from 'src/app/services/skill.service';

@Component({
    selector: 'app-ss-people',
    templateUrl: './ss-people.component.html',
    styleUrls: ['./ss-people.component.scss']
})

export class SsPeopleComponent implements OnInit, OnDestroy {
    @Input() typeData;
    @Input() type;
    @Input() singleType;

    notifications = NotificationText;
    personNotifications = PeopleText;
    formControl = new FormControl();
    storeData = {
        name: null,
        slug: null,
        brand_id: null,
        company_id: null,
        exists: false,
    };
    options;
    filteredOptions: Observable<any>;
    people = [];
    openInput:boolean = false;
    private subscribtion:any;
    private storeSubscribtion:any;
    private bulkSubscribtion:any;
    private updateSubscribtion:any;
    private updateModalSubscribtion:any;
    text = PeopleText;
    imageUrl = ImageUrl.peopleIcon;
    typeGroup = {
        group: 'people',
        type: {
            name: 'Person',
            plural: "people"
        }
    };
    selectedListItem: any;
    loadedContent:boolean = false;
    
    @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
    @ViewChild("inputField") inputField: ElementRef;

    tippyContent: NgxTippyProps = {
        allowHTML: true,
        interactive: true,
    };
    
    isPersonClicked:boolean;
    personClicked: any;
    routeActive: string;
    dataCount;
    focusInput: boolean = false;
    allPositions;
    isEmailGmail;
    allDataModal;
    showAllListCustomIsOpen = false;
    allSkills;

    constructor(
        private personService: PersonService,
        private notifier: NotifierService,
        private openDialogService: OpendialogService,
        private sidenavService: SidenavService,
        private route: ActivatedRoute,
        private keyContactService: KeyContactsService,
        private positionService: PositionService,
        private brandService: BrandService,
        private companyService: CompanyService,
        private generalService: GeneralService,
        public customDialog: CustomDialogService,
        private skillService: SkillService
    ) { 

        this.subscribtion = this.openDialogService.modalData.subscribe((res:any) => {
            if (res.type == 'person') {
                this.submit(res.data);
            }
        })

        this.subscribtion = this.openDialogService.bulkData.subscribe((res:any) => {
            if (res.type == 'bulkPersons') {
                this.bulkSubmit(res.data);
            }
        })

        this.personService.reloadPeople.subscribe(res => {
            this.getPeople();
        })

        this.customDialog.customModalisOpen.subscribe((res: any)=> {
            this.showAllListCustomIsOpen = res;
        });

        this.updateModalSubscribtion = this.openDialogService.updateModalData.subscribe((res: any) => {
            if(res.type.group == "people"){
                this.updatePerson(res.slug, res.data, res.positionChanged);            

                if(this.showAllListCustomIsOpen){
                    this.showAllListCustom();
                }
            }
        })
    }

    @HostListener('window:keyup.esc') onKeyUp() {
        this.resetInputForm();
    }

    ngOnInit(): void {
        if(this.type == 'brands'){
            this.storeData.brand_id = this.typeData.id;
        } else {
            this.storeData.company_id = this.typeData.id
        }
        this.sidenavService.cast.subscribe(data => this.isPersonClicked = data);
        this.routeActive = this.route.snapshot.params.slug;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.getPeople();
        }, 0);
        // this.listItems();
    }

    ngOnDestroy(): void {
        if(this.subscribtion){
            this.subscribtion.unsubscribe();
        }
        if (this.storeSubscribtion) {
            this.storeSubscribtion.unsubscribe();
        }
        if (this.bulkSubscribtion) {
            this.bulkSubscribtion.unsubscribe();
        }
        if (this.updateSubscribtion) {
            this.updateSubscribtion.unsubscribe();
        }
        if (this.updateModalSubscribtion) {
            this.updateModalSubscribtion.unsubscribe();
        }
    }

    listItems(){
        this.personService.getList().subscribe((res: any) => {
            if(this.selectedListItem){
                this.options = res.data.filter(ar => !this.selectedListItem.find(rm => (rm.slug === ar.slug) ));
            }else{
                this.options = res.data;
            }
            this.filterOptions();
        })
    }

    getPeople(){
        this.personService.getRelationshipsLimitData(this.type, this.typeData.slug).subscribe((res:any) => {
            this.dataCount = res.allDataCount;
            this.people = res.data;
            setTimeout(() => {
                this.loadedContent = true;
            }, 100);
            this.getPositions();
            this.getSkills();
        })
    }

    getPositions(){
        this.positionService.index().subscribe((res: any) => {
            this.allPositions = res.data;
        });
    }

    getSkills(){
        this.skillService.getList().subscribe((res:any) => {
            this.allSkills = res.data;
        });
    }

    filterOptions(){
        this.filteredOptions = this.formControl.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value?.name)),
            map(name => (name ? this._filter(name) : this.options.slice())),
        );
    }

    displayFn(person): string {
        return person && person.name ? person.name : '';
    }
    
    private _filter(name: string) {
        const filterValue = name.toLowerCase();
        return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    onInputEnter(e){
        if(this.formControl.value){
            this.storeData.name = this.formControl.value.slug ? this.formControl.value.slug : this.formControl.value;
            if(this.formControl.value.slug){
                this.submit();
            } else {
                this.openDialogService.openModal('person', AddDialogComponent, this.storeData, this.allPositions);
                this.resetInputForm()
            }
        }
    }

    submit(data=null){
        let submitData;
        if(data){
            submitData = data;
        } else {
            this.storeData.name = this.formControl.value.name ? this.formControl.value.name : this.formControl.value;
            if(this.formControl.value.name){
                this.storeData.exists = true;
                this.storeData.slug = this.formControl.value.slug;
            } else {
                this.storeData.exists = false;
                this.storeData.slug = null;
            }
            submitData = this.storeData
        }

        if(submitData){
            this.storeSubscribtion = this.personService.store(submitData).subscribe(
                data => this.handleResponse(data),
                error => this.handleError(error, data),
            );
        } else {
            setTimeout(() => {
                this.inputField?.nativeElement.focus();
            }, 200);
        }
        // this.positionService.refreshJsonPositionsCache();
    }

    updatePerson(id, data, positionChanged){
        this.personService.refreshRelPeopleCache(positionChanged);
        this.updateSubscribtion = this.personService.update(id, data).subscribe(
            data => this.handleUpdateResponse(data),
            error => this.handleUpdateError(error),
        );
    }

    showAllList() {
        this.personService.getRelationshipsAllData(this.type, this.typeData.slug).subscribe((res:any) => {
            this.openDialogService.openAllListModal(this.typeGroup, AllListDialogComponent, res.data);
        });
    }

    showAllListCustom(){
        this.personService.getRelationshipsAllData(this.type, this.typeData.slug).subscribe((res:any) => {
            this.allDataModal = res.data;
            if(res.data){
                setTimeout(() => {
                    this.customDialog.open();
                }, 200);
            }
            this.customDialog.customModalData.next({data:  res.data, type: this.typeGroup});
        });
    }

    toggleRightSidenav(person, event) {
        event.stopPropagation();
        const emailData = {
            email: person.email
        }
        if(person.email){
            this.generalService.checkEmailDns(emailData).subscribe((res: any) => {
                this.isEmailGmail = res;
                this.sidenavService.sidenavData.next({data:  person, type: this.typeGroup, isGmail: this.isEmailGmail});
                this.sidenavService.open();
            })
        }else{
            this.isEmailGmail = false;
            this.sidenavService.sidenavData.next({data:  person, type: this.typeGroup, isGmail: this.isEmailGmail});
            this.sidenavService.open();
        }
    }

    handleResponse(data) {
        this.personService.refreshRelPeopleCache(true);
        this.notifier.notify('success', this.notifications.relationship);
        this.getPeople();
        this.listItems();
        this.resetInputForm();
        this.storeSubscribtion.unsubscribe();
        this.keyContactService.refreshKeyContacts.next();
        
        if(this.type == 'brands'){
            this.brandService.refreshRelBrandsCache();
        }

        if(this.type == 'companies'){
            const typesOfCompanies = this.typeData.types[0].slug;
            this.companyService.refreshRelCompaniesCache(typesOfCompanies);
        }
    }

    handleError(error, data) {
        this.personService.deleteError(data).subscribe();
        this.notifier.notify('error', this.notifications.relationship_err);
        this.storeSubscribtion.unsubscribe();
    }

    handleUpdateResponse(data) {
        this.notifier.notify('success', this.personNotifications.updated);
        this.updateSubscribtion.unsubscribe();
        this.getPeople();
        this.listItems();
        this.personService.refreshPersonCache(data.data.slug);
        this.keyContactService.refreshKeyContactsCache(this.singleType, this.typeData.id);
        this.keyContactService.refreshKeyContacts.next();
        this.getPositions();
    }

    handleUpdateError(error) {
        this.notifier.notify('error', this.personNotifications.update_err);
        this.updateSubscribtion.unsubscribe();
    }

    add(){
        if(this.people?.length === 16){
            this.personService.getRelationshipsAllData(this.type, this.typeData.slug).subscribe((res:any) => {
                this.selectedListItem = res.data
                this.openInput = true;
                setTimeout(() => {
                    this.inputField?.nativeElement.focus()
                }, 200);
                this.listItems();
            });
        } else {
            this.selectedListItem = this.people
            this.openInput = true;
            setTimeout(() => {
                this.inputField?.nativeElement.focus()
            }, 200);
            this.listItems();
        }
    }

    resetInputForm(){
        this.openInput = false;
        this.storeData.name = '';
        this.autocomplete.closePanel();
        this.formControl.setValue(null);
    }
    
    deleteRelationship(slug, event){
        event.stopPropagation();
        this.personService.refreshRelPeopleCache(false);
        if(this.type == 'brands'){
            this.brandService.refreshRelBrandsCache();
        }
        if(this.type == 'companies'){
            const typesOfCompanies = this.typeData.types[0].slug;
            this.companyService.refreshRelCompaniesCache(typesOfCompanies);
        }

        this.personService.removeRelationship(slug, this.type, this.typeData.id).subscribe(
            data => this.handleDeleteRelationshipResponse(data),
            error => this.handleDeleteRelationshipError(error),
        )
    }

    handleDeleteRelationshipResponse(data){
        this.notifier.notify('success', this.notifications.relationshipDel);
        this.getPeople();
        this.listItems();
    }
    
    handleDeleteRelationshipError(error){
        this.notifier.notify('error', this.notifications.relationshipDel_err);
    }

    openBulk(){
        this.openDialogService.openBulkDialog('bulkPersons', BulkDialogComponent, this.storeData, this.allPositions, this.allSkills);
    }

    
    bulkSubmit(data){
        if(data){
            this.bulkSubscribtion = this.personService.bulk(data).subscribe(
                data => this.handleBulkResponse(data),
                error => this.handleBulkError(error, data),
            );
        } 
    }

    handleBulkResponse(data) {
        this.personService.refreshRelPeopleCache(true);
        this.skillService.refreshJsonSkillsCache();
        this.notifier.notify('success', this.notifications.bulk_added);
        this.getPeople();
        this.listItems();
        this.resetInputForm();
        this.bulkSubscribtion.unsubscribe();
        this.keyContactService.refreshKeyContacts.next();
    }

    handleBulkError(error, data) {
        this.personService.deleteError(data).subscribe();
        this.notifier.notify('error', this.notifications.bulk_added_err);
        this.bulkSubscribtion.unsubscribe();
    }
    
    closePopup() {
        this.customDialog.close();
    }
}
