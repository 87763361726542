import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { CompaniesText, NotificationText } from 'src/app/components/lang/globals-en';
import { CompanyTypesService } from 'src/app/services/company-types.service';
import { CompanyService } from 'src/app/services/company.service';
import { OpendialogService } from 'src/app/services/opendialog.service';
import { AddDialogComponent } from '../../dialogs/add-dialog/add-dialog.component';
import { SmallDialogComponent } from '../../dialogs/small-dialog/small-dialog.component';
import { SeoService } from 'src/app/services/seo.service';

@Component({
    selector: 'app-type',
    templateUrl: './type.component.html',
    styleUrls: ['./type.component.scss']
})
export class TypeComponent implements OnInit, OnDestroy {
    type: any;
    typeName;
    typeNamelw;
    typeId;
    data;
    submitData;
    companiesNotification = CompaniesText;
    error: null;
    errorStatus: null;
    private subscribtion:any;
    private deleteSubscribtion:any;

    constructor(private route: ActivatedRoute,
        private companyService: CompanyService,
        private companyTypeService: CompanyTypesService,
        private openDialogService: OpendialogService,
        private router: Router,
        private notifier: NotifierService,
        private seoService: SeoService) {

        this.subscribtion = this.openDialogService.modalData.subscribe((res:any) => {
            if (res.type == this.typeNamelw) {
                this.submitData = res.data
                this.submitData['companyTypes'] = [this.typeName]
                this.submit(this.submitData);
            }
        })
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.initialize()
            }
        });

        this.deleteListen();
    }

    ngOnInit(): void {
        this.initialize();
    }

    ngOnDestroy(): void {
        this.clearSubscribtion()
    }

    deleteListen(){
        this.deleteSubscribtion = this.openDialogService.deleteCompanyType.subscribe((res: any) => {
            this.delete(res.slug)
        })
    }

    initialize(){
        this.type = this.route.snapshot.params.type;
        this.seoService.setSeoMetaTags({title: this.type});
        this.companyTypeService.show(this.type).subscribe((res:any) => {
            this.typeId = res.data.id
            this.typeName = res.data.name
            this.typeNamelw = this.typeName.toLowerCase()
        })
        this.getCompaniesByType()
    }

    getCompaniesByType(){
        this.companyService.getListByType(this.type).subscribe((res:any) => {
            this.data = res
        })
    }

    clearSubscribtion(){
        if(this.subscribtion){
            this.subscribtion.unsubscribe();
        }
        if(this.deleteSubscribtion){
            this.deleteSubscribtion.unsubscribe();
        }
    }

    submit(data){
        this.companyTypeService.refreshTypeCache(this.route.snapshot.params.type)
        this.companyService.refreshJsonCompaniesCache(this.route.snapshot.params.type)

        this.companyService.store(data).subscribe(
            data => this.handleResponse(data),
            error => this.handleError(error, data),
        );
    }

    handleResponse(data) {
        this.notifier.notify('success', this.companiesNotification.added);
        this.getCompaniesByType();
        this.deleteSubscribtion.unsubscribe();
        this.deleteListen();
    }

    handleError(error, data) {
        this.companyService.deleteError(data).subscribe();
        this.notifier.notify('error', this.companiesNotification.add_err);
        this.error = error.error.message;
        this.errorStatus = error.status;
    }

    openDialog() {
        this.openDialogService.openModal(this.typeNamelw, AddDialogComponent);
    }
    
    openDelete(slug) {
        this.openDialogService.openDeleteDialog('companyType', SmallDialogComponent, slug);
    }

    delete(slug){
        this.companyService.refreshRelationsByTypeCache(this.route.snapshot.params.type)
        this.companyService.refreshJsonCompaniesCache(this.route.snapshot.params.type)
        this.companyService.delete(slug).subscribe(
            data => this.handleDeleteResponse(data),
            error => this.handleDeleteError(error),
        )
    }

    handleDeleteResponse(data) {
        this.notifier.notify('success', this.companiesNotification.removed);
        this.getCompaniesByType();
    }

    handleDeleteError(error) {
        this.notifier.notify('error', this.companiesNotification.remove_err);
        this.error = error.error.message;
        this.errorStatus = error.status;
    }
}
