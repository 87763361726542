<div style="padding-top: 100px" class="container-fluid">

    <div class="button-wrappers">
        <button (click)="openDialog()" class="new-person">Add new Person</button>
    </div>

    <div class="card">
        <div class="card-header">
            Person List
        </div>

        <div class="card-body">
            <div class="table-responsive">
                <table class=" table table-bordered table-striped table-hover datatable person-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th> Actions </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr data-entry-id="" *ngFor='let person of people;'>
                            <td>{{ person.id }}</td>
                            <td>{{ person.name }}</td>
                            <td>{{ person.email }}</td>
                            <td>{{ person.phone }}</td>
                            <td>
                                <a class="btn btn-xs btn-primary mr-2" routerLink="/people/{{ person.slug }}">
                                    View
                                </a>
                                <a class="btn btn-xs btn-danger" (click)="openDelete(person.slug)">
                                    Delete
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>