<div class="single-positions single-section active">
    <div class="positions-container single-container">
        <h2 class="f-sub-title-1">Positions</h2>
        <div class="positions-list--center list--center">

            <app-loading-module class="loader" [ngClass]="{'loaded': loadedContent}"></app-loading-module>

            <p class="f-body-4" [ngClass]="{'close': openInput, 'hidden': !loadedContent }">
                <ng-container *ngFor="let position of relationshipPositions; let i = index">
                    <span class="positionItem" title="{{ position.name }}">
                        <button id="positions-button" (click)="deleteRelationship(position.slug)">
                            <img src="./../../../../../assets/img/off_close.png" alt="delete">
                        </button>
                        {{ position.name }}
                    </span>
                </ng-container>
                <em *ngIf="!relationshipPositions" class="no-positions f-body-2">No Positions</em>

                <button class="f-button font-blue add" [ngClass]="{'open': openInput}" (click)="add()">+Add</button>
            </p>

            <div class="positions-input" [ngClass]="{'open': openInput}">
                <mat-form-field class="example-chip-list" [ngClass]="{'open': openInput}" appearance="standard">
                    <mat-label class="label">Position</mat-label>
                    <mat-chip-list #chipList aria-label="Position selection">
                        <mat-chip *ngFor="let position of positions" (removed)="removePosition(position)">
                            {{position}}
                            <button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip>
                        <input placeholder="New Position..." #positionInput matInput [formControl]="position"
                            [matAutocomplete]="auto" [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="addPosition($event)" class="input">
                    </mat-chip-list>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                        <mat-option *ngFor="let position of filteredPositions | async" [value]="position.id">
                            {{position.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
        <button class="f-button font-blue save" [ngClass]="{'open': openInput}" (click)="submit()">Save</button>
    </div>
</div>