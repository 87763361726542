import { CommonModule } from "@angular/common";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CompaniesComponent } from "./companies.component";

const routes: Routes = [
    { 
      path: '', 
      component: CompaniesComponent,
    }
  ]

@NgModule({
    declarations: [CompaniesComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes)
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
})

export class CompaniesModule{

}