import { CommonModule } from "@angular/common";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { SidenavComponent } from "./sidenav.component";
import { RouterModule } from '@angular/router';


@NgModule({
    declarations: [
        SidenavComponent
    ],
    imports: [
        CommonModule,
        RouterModule
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],

    exports: [
        SidenavComponent,
    ]

})


export class SidenavModule{ 
}