<div class="all-list-new-modalbox" *ngIf="allData" [class.show]="opened">
    <div class="all-list-new-dialog">
        <div class="all-list-new_container">
            <div mat-dialog-content class="all-list-new-dialog-content">
                <div class="all-list-new-dialog-header">
                    <h3 mat-dialog-title>{{ typeGroup?.group}}</h3>
                    <button class="close" (click)="closePopup()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="body-content">
                    <div class="all-data">
                        <ng-container *ngFor="let data of allData; let i = index">
                            <div class="single-data" [ngxTippy]="tippyTemplate" (click)="openRightSidenav(data, $event)" data-tippy-delay="[350, 350]" [tippyProps]="tippyContent">
                                <div *ngIf="(typeGroup.group === 'people' || typeGroup.group === 'person-key-contact') else logoTemplate">
                                    <div *ngIf="data.avatar; else noAvatarImg">
                                        <div  class="data-logo avatar" >
                                            <img src="{{ imageUrlType }}/{{ data.avatar }}">
                                        </div>
                                    </div>
                                    <ng-template #noAvatarImg>
                                        <div  class="data-logo avatar">
                                            <img src="./../../../../../assets/img/default-logo.png" alt="avatar">
                                        </div>
                                    </ng-template>
                                </div> 
                                <ng-template #logoTemplate>
                                    <div *ngIf="data.logo; else noLogoImg">
                                        <div class="data-logo">
                                            <img src="{{ imageUrlType }}/{{ data.logo }}">
                                        </div>
                                    </div>
                                    <ng-template #noLogoImg>
                                        <div class="data-logo">
                                            <img src="./../../../../../assets/img/default-logo.png" alt="logo">
                                        </div>
                                    </ng-template>
                                </ng-template>
                                <div class="data-name">
                                    <h3 class="f-sub-header-1">{{ data.name }}</h3>
                                </div>
                                <template #tippyTemplate>
                                    <app-tooltip [data]="data" [typePosition]="typeGroup"></app-tooltip>
                                </template>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-sidenav [dataNav]="itemClicked" [typePosition]="typeGroup"></app-sidenav>



