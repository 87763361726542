import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, Inject, OnInit, Optional, HostListener, OnDestroy, Directive } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LoginText, NotificationText } from '../../../lang/globals-en';
import { OpendialogService } from 'src/app/services/opendialog.service';
import { CompanyTypesService } from 'src/app/services/company-types.service';
import { UploadService } from 'src/app/services/upload.service';

@Component({
    selector: 'app-add-dialog',
    templateUrl: './add-dialog.component.html',
    styleUrls: ['./add-dialog.component.scss'],
})

export class AddDialogComponent implements OnInit {
    modalFormGroup: FormGroup;
    separatorKeysCodes: number[] = [ENTER, COMMA, TAB];
    notifications = NotificationText;
    text = LoginText;
    error: null;
    errorStatus: null;
    type: any;
    name;
    profilePicture: File; 
    
    countryFormControl = new FormControl();
    country_id;
    country;

    position = new FormControl();
    filteredPositions: Observable<any[]>;
    positions: any[] = [];
    positionValues: any[] = [];
    allPositions: any[];

    companyType = new FormControl();
    filteredCompanyTypes: Observable<any[]>;
    companyTypes: any[] = [];
    companyTypesData;
    companyTypeValues: any[] = [];
    allCompanyTypes: any[];
    
    industry = new FormControl();
    filteredIndustries: Observable<any[]>;
    industries: any[] = [];
    industryValues: any[] = [];
    allIndustries: any[];
    brandSelectIndustries: any[] = [];

    focusInput: boolean = false;
    modalEnd: boolean = false;
    addOnBlur = true;

    // showSocialsRow: boolean = false;

    pondOptions = {
        class: 'my-filepond',
        multiple: false,
        labelIdle: '<img src="/assets/img/default-logo.png" alt="logo">',
        acceptedFileTypes: 'image/jpeg, image/png',
        imageCropAspectRatio: '1:1',
        stylePanelLayout: 'compact circle',
        styleLoadIndicatorPosition: 'center bottom',
        styleProgressIndicatorPosition: 'center bottom',
        styleButtonRemoveItemPosition: 'center bottom',
        styleButtonProcessItemPosition: 'center bottom',
    };

    @ViewChild('positionInput') positionInput: ElementRef<HTMLInputElement>;
    @ViewChild('industryInput') industryInput: ElementRef<HTMLInputElement>;
    @ViewChild('companyTypeInput') companyTypeInput: ElementRef<HTMLInputElement>;
    @ViewChild('nameInput') nameInput: ElementRef;
    @ViewChild('myPond') myPond: any;

    public  constructor(
        @Optional() public dialogRef: MatDialogRef<AddDialogComponent>,
        private formBuilder: FormBuilder,
        private companyTypeService: CompanyTypesService,
        private openDialogService: OpendialogService,
        private uploadService: UploadService,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
        this.country = this.data.countries;
        this.type = this.data.type;
        if(this.data.ssData?.companyTypes){
            this.companyTypesData = this.data.ssData.companyTypes;
        }
    }

    @HostListener('window:keyup.esc') onKeyUp() {
        this.dialogRef.close();
    }

    ngOnInit(): void {
        if (this.type == 'person' || this.type == 'person-key-contact') {
            this.getPositions();
        }
        if (this.type == 'brand') {
            this.getIndustries();
        }
        if (this.type == 'company') {
            this.getCompanyTypes();
            this.modalFormGroup = this.formBuilder.group({
                name: [null, [Validators.required]],
                website: [null, [Validators.pattern('^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$')]],
                email: [null, [Validators.email]],
                logo: '',
                image_token: '',
                description: '',
                phone: '',
                address: '',
                country_id: '',
                behance: '',
                facebook: '',
                linkedin: '',
                instagram: '',
                twitter: '',
                vimeo: '',
                dribbble: '',
                pinterest: '',
                youtube: '',
                positions: [null, null],
                industries: [null, null],
                companyTypes: [null, Validators.required],
                person_id: '',
                brand_id: '',
                company_id: '',
                collaborator_id: '',
            });
        } else {
            this.modalFormGroup = this.formBuilder.group({
                name: [null, [Validators.required]],
                person: '',
                website: [null, [Validators.pattern('^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$')]],
                email: [null, [Validators.email]],
                logo: '',
                image_token: '',
                description: '',
                phone: '',
                address: '',
                country_id: '',
                behance: '',
                facebook: '',
                linkedin: '',
                instagram: '',
                twitter: '',
                vimeo: '',
                dribbble: '',
                pinterest: '',
                youtube: '',
                positions: [null, null],
                industries: [null, null],
                companyTypes: [null, null],
                person_id: '',
                brand_id: '',
                company_id: '',
                collaborator_id: '',
            });
        }
        
        if (this.data.ssData) {
            if(this.data.ssData.person){
                this.modalFormGroup.get('name').setValue(this.data.ssData.person);
                this.modalFormGroup.get('person').setValue(this.data.ssData.person);
            }else{
                this.modalFormGroup.get('name').setValue(this.data.ssData.name);
            }
            this.modalFormGroup.get('person_id').setValue(this.data.ssData.person_id);
            this.modalFormGroup.get('brand_id').setValue(this.data.ssData.brand_id);
            this.modalFormGroup.get('company_id').setValue(this.data.ssData.company_id);
            this.modalFormGroup.get('collaborator_id').setValue(this.data.ssData.collaborator_id);
            this.modalFormGroup.get('companyTypes').setValue(this.data.ssData.companyTypes);
        }
        this.focusInput = true;
        
    }

    pondHandleAddFile(event: any) {
        this.profilePicture = event.pond.getFile().file;
    }

    submit(): void {
        this.error = null;
        this.errorStatus = null;
        this.modalFormGroup.get('positions').setValue(this.positionValues);
        this.modalFormGroup.get('industries').setValue(this.industryValues);
        
        if (this.modalFormGroup.value.country_id?.id !== '' || this.modalFormGroup.value.country_id?.id !== null) {
            this.modalFormGroup.get('country_id').setValue(this.modalFormGroup.value.country_id?.id);
        } else {
            this.modalFormGroup.value.country_id.id = '';
        }
        if(this.profilePicture){
            this.uploadService.uploadImage(this.profilePicture, this.type).subscribe( res => {
                if (res.url) {
                    let filename = res.url.substring(res.url.lastIndexOf("/") + 1, res.url.length);
                    this.modalFormGroup.get('logo').setValue(filename);
                    this.modalFormGroup.get('image_token').setValue(res.public_id);
                    this.openDialogService.modalData.next({ type: this.type, data: this.modalFormGroup.value });
                    this.dialogRef.close();
                }
            })
        } else {
            this.openDialogService.modalData.next({ type: this.type, data: this.modalFormGroup.value });
            this.dialogRef.close();
        }
        if (this.type == 'company') {
            this.companyTypeService.refreshCompanyType.next();
        }
    }

    // -- POSITION INPUT WITH SELECT 2 -- //
    getPositions() {
        this.allPositions = this.data.positions;
        this.filterPositions();
    }

    filterPositions() {
        this.filteredPositions = this.position.valueChanges.pipe(
            startWith(null),
            map((position: string | null) => (position ? this._filterPositions(position) : this.allPositions.slice())),
        );
    }
    
    private _filterPositions(value: string): string[] {
        const filterValue = value.toString().toLowerCase();
        return this.allPositions.filter(position => position.name.toString().toLowerCase().includes(filterValue));
    }

    addPosition(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (value) {
            if(!this.positions.includes(value)) {
                this.positions.push(value);
                this.positionValues.push(value);
            }
        }
        if (event.input) {
            event.input.value = '';
        }
        this.position.setValue(null);
    }

    removePosition(position: string): void {
        const index = this.positions.indexOf(position);
        const valueByName = this.positionValues.indexOf(position);
        let valueId;
        this.allPositions.forEach(item => {
            if(position == item.name){
                valueId = item.id
            }
        });

        if (index >= 0) {
            this.positions.splice(index, 1);
        }
        if (valueByName >= 0) {
            this.positionValues.splice(valueByName, 1);
        }
        if (valueId) {
            const arrayIndex = this.positionValues.indexOf(valueId);
            this.positionValues.splice(arrayIndex, 1);
        }
    }

    selectedPosition(event: MatAutocompleteSelectedEvent): void {
        if(!this.positions.includes(event.option.viewValue)) {
            this.positions.push(event.option.viewValue);
            this.positionValues.push(event.option.value);
        }
        this.positionInput.nativeElement.value = '';
        this.position.setValue(null);
        this.position.disable();
        this.position.enable();
    }
    // -- END OF POSITION INPUT WITH SELECT 2 -- //


    // -- COMPANY TYPE INPUT WITH SELECT 2 -- //
    getCompanyTypes() {
        this.allCompanyTypes = this.data.allCompanyTypes;
        this.filterCompanyTypes();
    }

    filterCompanyTypes() {
        this.filteredCompanyTypes = this.companyType.valueChanges.pipe(
            startWith(null),
            map((companyType: string | null) => (companyType ? this._filterCompanyTypes(companyType) : this.allCompanyTypes.slice())),
        );
    }
    
    private _filterCompanyTypes(value: string): string[] {
        const filterValue = value.toString().toLowerCase();
        return this.allCompanyTypes.filter(companyType => companyType.name.toString().toLowerCase().includes(filterValue));
    }

    removeCompanyType(companyType: string): void {
        const index = this.companyTypes.indexOf(companyType);
        const valueByName = this.companyTypeValues.indexOf(companyType);
        let valueId;
        this.allCompanyTypes.forEach(item => {
            if(companyType == item.name){
                valueId = item.id
            }
        });

        if (index >= 0) {
            this.companyTypes.splice(index, 1);
        }
        if (valueByName >= 0) {
            this.companyTypeValues.splice(valueByName, 1);
            this.modalFormGroup.get('companyTypes').setValue(this.companyTypeValues);
        }
        if (valueId) {
            const arrayIndex = this.companyTypeValues.indexOf(valueId);
            this.companyTypeValues.splice(arrayIndex, 1);
            this.modalFormGroup.get('companyTypes').setValue(this.companyTypeValues);
        }
    }

    selectedCompanyType(event: MatAutocompleteSelectedEvent): void {
        if(!this.companyTypes.includes(event.option.viewValue)) {
            this.companyTypes.push(event.option.viewValue);
            this.companyTypeValues.push(event.option.value);
            this.modalFormGroup.get('companyTypes').setValue(this.companyTypeValues);
        }
        this.companyTypeInput.nativeElement.value = '';
        this.companyType.setValue(null);
        this.companyType.disable();
        this.companyType.enable();
    }
    // -- END OF COMPANY TYPE INPUT WITH SELECT 2 -- //
    
     // -- INDUSTRY INPUT WITH SELECT 2 -- //
    getIndustries(){
        if(this.brandSelectIndustries){
            this.allIndustries = this.data.industries.filter(ar => !this.brandSelectIndustries.find(rm => (rm === ar.name)));
        }else{
            this.allIndustries = this.data.industries;
        }
        this.filterIndustries();
    }
    
    filterIndustries() {
        this.filteredIndustries = this.industry.valueChanges.pipe(
            startWith(null),
            map((industry: string | null) => (industry ? this._filterIndustries(industry) : this.allIndustries.slice())),
        );
    }

    private _filterIndustries(value: string): string[] {
        const filterValue = value.toString().toLowerCase();
        return this.allIndustries.filter(industry => industry.name.toString().toLowerCase().includes(filterValue));
    }
    
    addIndustry(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (value) {
            if(!this.industries.includes(value)) {
                this.industries.push(value);
                this.industryValues.push(value);
            }
        }
        if (event.input) {
            event.input.value = '';
        }
        this.industry.setValue(null);
    }

    removeIndustry(industry: string): void {
        const index = this.industries.indexOf(industry);
        const valueByName = this.industryValues.indexOf(industry);
        let valueId;
        this.allIndustries.forEach(item => {
            if(industry == item.name){
                valueId = item.id
            }
        });

        if (index >= 0) {
            this.industries.splice(index, 1);
        }
        if (valueByName >= 0) {
            this.industryValues.splice(valueByName, 1);
        }
        if (valueId) {
            const arrayIndex = this.industryValues.indexOf(valueId);
            this.industryValues.splice(arrayIndex, 1);
        }
    }
    
    selectedIndustry(event: MatAutocompleteSelectedEvent): void {
        if(!this.industries.includes(event.option.viewValue)) {
            this.industries.push(event.option.viewValue);
            this.industryValues.push(event.option.value);
        }
        this.industryInput.nativeElement.value = '';
        this.industry.setValue(null);
        this.industry.disable();
        this.industry.enable();
    }
    // -- END OF INDUSTRY INPUT WITH SELECT 2 -- //

    // showSocials(){
    //     this.showSocialsRow = true;
    // }
}